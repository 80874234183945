import React, { useMemo, useState } from 'react'
import http from '@/lib/http';
import { Checkbox, useToast } from '@chakra-ui/react'
import { PinInput, PinInputField } from '@chakra-ui/react'
import { BadRequestError, handleResponseError } from '@/utils/errors';
import { useApp } from '@/contexts/appContext';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { FaSpinner } from 'react-icons/fa6';
import CustomInput from '@/components/CustomInput';
import { IUser, IWallet } from '@/types/user.types';
import classNames from 'classnames';

function Login() {

    const [searchParams, setSearchParams] = useSearchParams();

    const urlTab = useMemo((): "phone" | "email" => {
        const p = searchParams.get("method");
        if (!p) return "phone";
        if (["phone", "email"].includes(p)) {
            return p as "phone" | "email";
        } else {
            return "phone"
        }
    }, [searchParams])

    const [methodTab, setMethodTab] = useState(urlTab);
    const [tab, setTab] = useState<1 | 2>(1);

    const [loginInput, setLoginInput] = useState({
        remember_me: "No",
        phone: "",
        email: "",
        pin: ""
    });
    const [confirmPinInput, setConfirmPinInput] = useState("");
    const [referenceCode, setReferenceCode] = useState("");
    const [isFetching, setIsFetching] = useState(false);

    const { setShowOnboard, setWallets, setUser, setIsAuthenticated } = useApp();
    const navigate = useNavigate();
    const toast = useToast();

    async function signin() {
        setIsFetching(true);
        console.log(loginInput)
        try {
            const resp_ = methodTab === "phone" ?
                http("user/login/phone-pin", { phone: `+234${loginInput.phone}`, pin: loginInput.pin, remember_me: loginInput.remember_me }, process.env.REACT_APP_PUBLIC_KEY) :
                http("user/login/email-pin", { email: loginInput.email, pin: loginInput.pin, remember_me: loginInput.remember_me }, process.env.REACT_APP_PUBLIC_KEY);
            const resp = await resp_;
            if ((resp.data as any).reference_code) {
                const otpResp = await http("user/login/2fa", { reference_code: (resp.data as any).reference_code, mode: "send_code" }, process.env.REACT_APP_PUBLIC_KEY)
                toast({
                    title: 'Success',
                    description: otpResp.message,
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                })
                setReferenceCode((resp.data as any).reference_code)
                setTab(2);
                setIsFetching(false);
                return;
            }
            await completeAuth(resp);
        } catch (error) {
            console.log(error);
            setIsFetching(false);
            handleResponseError(error, toast);
        }
    }

    async function completeAuth(resp: any) {
        const _user = (resp.data as IUser | undefined);
        if (_user) {
            const _kycDetails = _user.kyc.details;

            const getBvnKyc = _kycDetails.find((e) => e.parameter === "bvn");
            if (!getBvnKyc) throw new BadRequestError("Authentication failed: try again later");

            if (_user.kyc.auth.phone_verified === "No") {
                const verifyResp = await http("user/verification/process/phone", { mode: "send_otp" }, _user.token);
                console.log("verifyResp:", verifyResp);
                setShowOnboard("phone");
            } else if (getBvnKyc.status !== "Successful") {
                setShowOnboard("bvn");
            } else {
                const getWallet = await http<IWallet[]>("wallet/manager/details", {}, _user.token);
                setWallets(getWallet.data!);
            }
            setUser(_user)
            localStorage.setItem('op-app-tkn', _user.token!);
            setIsAuthenticated(true);
            console.log("logged in user:", _user)
            navigate("/u/dashboard");
        } else {
            throw new BadRequestError("Authentication failed: invalid response");
        }
    }

    function changeTab(tab: any) {
        setLoginInput((i) => ({ ...i, phone: "", email: "" }));
        setMethodTab(tab);
        setSearchParams({ method: tab });
    }

    async function authenticateSignUp() {
        setIsFetching(true);
        console.log(loginInput)
        try {
            const resp = await http("user/login/2fa", { reference_code: referenceCode, mode: "verify_code", otp: confirmPinInput, remember_me: loginInput.remember_me }, process.env.REACT_APP_PUBLIC_KEY)
            await completeAuth(resp);
        } catch (error) {
            console.log(error);
            setIsFetching(false);
            handleResponseError(error, toast);
        }
    }

    async function resendOtp() {
        setIsFetching(true);
        try {
            const verifyResp = await http("user/login/2fa", { reference_code: referenceCode, mode: "send_code" }, process.env.REACT_APP_PUBLIC_KEY)
            // const verifyMsg = verifyResp.message.split(" ");
            // alert(verifyMsg[verifyMsg.length - 1])
            toast({
                title: 'Success',
                description: verifyResp.message,
                status: 'success',
                duration: 5000,
                isClosable: true,
            })
            console.log("verify response:", verifyResp);
            setIsFetching(false)
        } catch (error) {
            console.log(error);
            setIsFetching(false);
            handleResponseError(error, toast);
        }
    }

    return (
        <>
            <div className='px-6'>
                <div className='block md:flex w-full justify-center mt-0 md:mt-[50px]'>
                    <div className='bg-none md:bg-white dark:bg-none dark:md:bg-neutral-800 px-2 py-2 md:px-[60px] md:py-[80px] w-full lg:w-[70%] rounded-xl'>

                        <div className='text-black dark:text-neutral-100 '>

                            <div className='block md:flex justify-center w-full'>
                                <div className=' w-full md:w-[300px] lg:w-[400px] px-0 md:px-5'>

                                    {
                                        tab === 1 ?
                                            <div>
                                                <div className='mb-8'>
                                                    <div className='mb-3 font-extrabold text-xl'>Welcome !</div>
                                                    <div>Don't have an account? <Link className='text-purple-600 dark:text-purple-400' to={'/auth/signup'}>Create one now</Link></div>
                                                </div>

                                                <div className='mb-10 px-0'>
                                                    <div className='flex justify-between'>
                                                        <div className='flex items-center gap-2'>
                                                            <button onClick={() => changeTab("phone")} className={classNames('md:flex gap-2 items-center rounded-md md:rounded-full py-1 px-5 text-sm/6 font-semibold dark:text-white focus:outline-none dark:hover:bg-white/5 hover:bg-slate-200 focus:outline-3 focus:outline-purple-700', {
                                                                'outline-none outline-3 outline-purple-700': methodTab === "phone",
                                                                '': methodTab !== "phone"
                                                            })}>
                                                                <div className='md:pr-0'>Phone</div>
                                                            </button>
                                                            <button onClick={() => changeTab("email")} className={classNames('md:flex gap-2 items-center rounded-md md:rounded-full py-1 px-5 text-sm/6 font-semibold dark:text-white focus:outline-none dark:hover:bg-white/5 hover:bg-slate-200 focus:outline-3 focus:outline-purple-700', {
                                                                'outline-none outline-3 outline-purple-700': methodTab === "email",
                                                                '': methodTab !== "email"
                                                            })}>
                                                                <div className='md:pr-0'>Email</div>
                                                            </button>

                                                        </div>
                                                    </div>
                                                </div>

                                                {
                                                    methodTab === "phone" ?
                                                        <div className='mb-5'>
                                                            <div className='text-sm font-normal mb-1'>Phone number</div>
                                                            <CustomInput
                                                                type='number'
                                                                name='phone'
                                                                value={loginInput.phone}
                                                                onChange={(e) => setLoginInput((i) => ({ ...i, phone: e }))}
                                                                placeholder='Phone'
                                                                icon={'+234'}
                                                                disabled={isFetching}
                                                            />
                                                        </div> :
                                                        <div className='mb-5'>
                                                            <div className='text-sm font-normal mb-1'>Email</div>
                                                            <CustomInput
                                                                type='email'
                                                                name='email'
                                                                value={loginInput.email}
                                                                onChange={(e) => setLoginInput((i) => ({ ...i, email: e }))}
                                                                placeholder='Email'
                                                                disabled={isFetching}
                                                            />
                                                        </div>
                                                }

                                                <div className='mb-5'>
                                                    <div className='text-sm font-normal mb-1'><span className='text-red-400'>*</span> PIN</div>
                                                    <CustomInput
                                                        type='password'
                                                        value={loginInput.pin}
                                                        onChange={(e) => setLoginInput((i) => ({ ...i, pin: e }))}
                                                        placeholder='******'
                                                        disabled={isFetching}
                                                    />
                                                </div>

                                                <div className='mb-5'>
                                                    <div className='flex gap-3 items-center px-1'>
                                                        <Checkbox
                                                            onChange={(e) => e.target.checked ? setLoginInput((i) => ({ ...i, remember_me: "Yes" })) : setLoginInput((i) => ({ ...i, remember_me: "No" }))}>
                                                            <span className='text-sm text-stone-800 dark:text-stone-200'>Remember me</span>
                                                        </Checkbox>
                                                    </div>
                                                </div>

                                                <div className='mb-5'>
                                                    <button onClick={() => signin()} disabled={isFetching} className='justify-center flex w-full text-sm text-neutral-100 font-bold bg-purple-800 shadow-md dark:bg-purple-700 px-5 py-4 rounded-lg disabled:bg-opacity-50 dark:disabled:disabled:bg-opacity-20'>
                                                        {
                                                            isFetching ?
                                                                <FaSpinner className='animate-spin' /> :
                                                                <span>Log in</span>
                                                        }
                                                    </button>
                                                </div>
                                            </div> :
                                            <div>

                                                <center>
                                                    <div className='mb-3'>
                                                        <img src="/assets/message-img2.svg" className='w-[150px] md:w-[200px] h-[150px] md:h-[200px]' alt="" />
                                                    </div>

                                                    <div className='mb-8 w-full'>
                                                        <PinInput onChange={(e) => setConfirmPinInput(e)}>
                                                            <PinInputField className='mr-2' />
                                                            <PinInputField className='mr-2' />
                                                            <PinInputField className='mr-2' />
                                                            <PinInputField className='mr-2' />
                                                            <PinInputField className='mr-2' />
                                                            <PinInputField className='' />
                                                        </PinInput>
                                                    </div>

                                                    <div className='mb-8'>
                                                        <div className='mb-3 font-extrabold text-xl'>We just sent you an OTP</div>
                                                        <div className='text-sm'>You have 2FA enabled, please enter the code we sent to your {methodTab === "phone" ? "phone number" : methodTab}</div>
                                                    </div>

                                                    <div className='mb-8'>
                                                        <div><button className='text-purple-600 dark:text-purple-400' onClick={() => authenticateSignUp()}>
                                                            {
                                                                isFetching ?
                                                                    <FaSpinner /> :
                                                                    <span>Verify login</span>
                                                            }
                                                        </button></div>
                                                    </div>

                                                    <div className='inline-block'>
                                                        <div className='mb-5 flex gap-3 text-xs'>
                                                            <div>Didn't get a code ? </div>
                                                            <button className='text-purple-600 dark:text-purple-400' onClick={() => resendOtp()} disabled={isFetching}>
                                                                {
                                                                    isFetching ?
                                                                        <FaSpinner className='animate-spin' /> :
                                                                        <span>resend</span>
                                                                }
                                                            </button>
                                                        </div>
                                                    </div>
                                                </center>
                                            </div>
                                    }

                                    <div className='py-8 md:py-0'></div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className='py-0 md:py-10'></div>

        </>
    )
}

export default Login