"use client"

import React, { createContext, useContext, useEffect, useState } from "react"
import { IUser, IWallet } from "@/types/user.types";
import { AuthCred, IAppContext } from "@/types/global.types";
import http from "@/lib/http";
import { BadRequestError } from "@/utils/errors";

export const AppContext = createContext<IAppContext | undefined>(undefined);

function AppProvider({ children }: { children: React.ReactNode }) {

  const [onlineStatus, setOnlineStatus] = useState<"online" | "offline">(
    navigator.onLine ? "online" : "offline"
  );

  const [user, setUser] = useState<IUser | null>(null);
  const [wallets, setWallets] = useState<IWallet[]>([]);
  const [showOnboard, setShowOnboard] = useState<"phone" | "bvn" | "email" | null>(null);
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [authCred, setAuthCred] = useState<AuthCred | null>(null);
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, [])

  async function getUserDatas() {
    setIsAuthenticating(true);
    try {
      const getUser = await http<IUser>("user/account/details");
      const _user = getUser.data!;
      const _kycDetails = _user.kyc.details;

      const getBvnKyc = _kycDetails.find((e) => e.parameter === "bvn");
      if (!getBvnKyc) throw new BadRequestError("Authentication failed: try again later");

      if (_user.kyc.auth.phone_verified === "No") {
        setShowOnboard("phone");
      }else if (_user.kyc.auth.email_verified === "No") {
        setShowOnboard("email");
      }else if(getBvnKyc.status === "Pending") {
        setShowOnboard("bvn");
      }else {
        const getWallet = await http<IWallet[]>("wallet/manager/details");
        setWallets(getWallet.data!);
      }
      setUser(_user)
      setIsAuthenticated(true);
      console.log(_user)
      setIsAuthenticating(false);
    } catch (error) {
      console.log(error);
      setIsAuthenticating(false);
    }
  }

  useEffect(() => {
    getUserDatas();
  }, []);

  async function logout() {
    setIsAuthenticating(true);
    try {
      await http("/user/account/logout");
      localStorage.removeItem('op-app-tkn');
      localStorage.removeItem('op-app-onboard');
      setIsAuthenticated(false);
      setIsAuthenticating(false);
      setUser(null);
    } catch (error) {
      console.log(error)
      setIsAuthenticating(false);
    }
  }  

  const updateOnlineStatus = () => {
    setOnlineStatus(navigator.onLine ? "online" : "offline");
  };

  useEffect(() => {
    window.addEventListener("online", updateOnlineStatus);
    window.addEventListener("offline", updateOnlineStatus);

    // Cleanup event listeners when the component is unmounted
    return () => {
      window.removeEventListener("online", updateOnlineStatus);
      window.removeEventListener("offline", updateOnlineStatus);
    };
  }, []);

  return (
    <AppContext.Provider value={{
      isMounted,
      user,
      setUser,
      wallets,
      setWallets,
      isAuthenticating,
      isAuthenticated,
      setIsAuthenticated,
      showOnboard,
      setShowOnboard,
      authCred,
      setAuthCred,
      logout,
      onlineStatus
    }}>
      {children}
    </AppContext.Provider>
  )
}

export function useApp() {
  return useContext<IAppContext | undefined>(AppContext)!;
}

export default AppProvider