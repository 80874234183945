import React, { useEffect, useMemo, useRef, useState } from 'react'
import classNames from 'classnames';
import { FaEyeSlash } from 'react-icons/fa6';
import { currencyFormat } from '@/utils/helpers';
import { useApp } from '@/contexts/appContext';
import { Swiper, SwiperRef, SwiperSlide, useSwiper } from 'swiper/react';
import { EffectCoverflow, Pagination } from 'swiper/modules';
import { unavailableWallets, walletConfig, walletList } from '@/utils/constants';
import { useSearchParams } from 'react-router-dom';
import AddFundsModal from './modals/AddFundsModal';
import CreateWallet from './modals/CreateWallet';

function CustomWalletSelectButton({
    walletConfig,
    activeWallet,
    isActive
}: {
    walletConfig: {
        currency: string;
        color: string;
        icon: string;
    }[],
    activeWallet: number,
    isActive: boolean
}) {

    const swiperInstance = useSwiper();

    function goToSlide(i: number) {
        if (activeWallet !== i) {
            swiperInstance.slideToLoop(i)
        }
    }

    return (
        <div className={classNames('flex transition transform gap-3 text-sm ease-in duration-300', {
            "opacity-100 translate-y-0": isActive,
            "opacity-0 translate-y-full": !isActive
        })}>
            <button className={classNames('p-1', {
                "font-bold text-white": walletConfig[activeWallet].currency === "ngn",
                "font-light text-neutral-200 text-opacity-90": walletConfig[activeWallet].currency !== "ngn"
            })} onClick={() => goToSlide(0)}>NGN</button>
            <button className={classNames('p-1', {
                "font-bold text-white": walletConfig[activeWallet].currency === "usd",
                "font-light text-neutral-200 text-opacity-90": walletConfig[activeWallet].currency !== "usd"
            })} onClick={() => goToSlide(1)}>USD</button>
            <button className={classNames('p-1', {
                "font-bold text-white": walletConfig[activeWallet].currency === "eur",
                "font-light text-neutral-200 text-opacity-90": walletConfig[activeWallet].currency !== "eur"
            })} onClick={() => goToSlide(2)}>EUR</button>
            <button className={classNames('p-1', {
                "font-bold text-white": walletConfig[activeWallet].currency === "gbp",
                "font-light text-neutral-200 text-opacity-90": walletConfig[activeWallet].currency !== "gbp"
            })} onClick={() => goToSlide(3)}>GBP</button>
        </div>
    )
}

function WalletSlider({
    size = "normal",
    onSliderChange = (_) => { return; }
}: {
    size: "normal" | "full",
    onSliderChange?: (wallet: string) => void
}) {

    const [searchParams] = useSearchParams();
    const urlWallet = useMemo(() => {
        const w = searchParams.get('wallet');
        if (w) {
            const validParams = ["ngn", "usd", "eur", "gbp"];
            if (validParams.includes(w)) {
                return validParams.indexOf(w)
            } else {
                return 0;
            }
        } else {
            return 0;
        }
    }, [searchParams]);

    // console.log(urlWallet)

    const swiperRef = useRef<SwiperRef | null>(null);

    const [activeWallet, setActiveWallet] = useState(urlWallet);
    const [showBalance, setShowBalance] = useState(true);

    const { wallets } = useApp();

    async function toggleShowBalance() {
        setShowBalance((s) => s ? false : true);
    }

    useEffect(() => {
        onSliderChange(walletList[activeWallet]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeWallet])


    return (
        <>
            <Swiper
                ref={swiperRef}
                effect={'coverflow'}
                grabCursor={true}
                centeredSlides={true}
                slidesPerView={'auto'}
                coverflowEffect={{
                    rotate: 50,
                    stretch: 0,
                    depth: 100,
                    modifier: 1,
                    slideShadows: true,
                }}
                pagination={true}
                modules={[EffectCoverflow, Pagination]}
                loop={true}
                className='wallet-coverflow-swiper'
                onSlideChange={(s: any) => {
                    setActiveWallet(s.realIndex)
                }}
                onInit={(s: any) => {
                    const remap = {
                        '0': 2,
                        '1': 3,
                        '2': 0,
                        '3': 1
                    }
                    s.slideTo((remap as any)[`${urlWallet}`]);
                }}
            >
                {walletConfig.map((el, i) => (
                    <SwiperSlide key={i} className={classNames(`${size === "normal" ? 'wallet-coverflow-swiper-slide' : size === "full" ? 'wallet-coverflow-swiper-slide-full' : ''}
                        w-[350px] md:w-[400px] ${el.color}`)}
                    >
                        <div className='w-full h-[inherit] bg-teal-200 bg-opacity-0 px-6 py-8 text-white relative overflow-hidden'>

                            <div className={`absolute left-[66.87%] right-[-8.44%] top-[-37.93%] bottom-[53.63%] w-[200px] h-[200px] rounded-full bg-purple-600 bg-opacity-70`}></div>

                            <div className='flex items-center justify-between gap-3 w-full mb-5 relative z-20'>
                                <div>
                                    <div className='text-xs'>{el.currency.toUpperCase()} WALLET</div>
                                    <div className='flex items-center gap-1 mb-0'>
                                        <div className='font-bold text-xl'>
                                            <span>
                                                {
                                                    showBalance ?
                                                        wallets.find((e) => e.currency === el.currency) ?
                                                            currencyFormat(wallets.find((e) => e.currency === el.currency)!.available_balance, el.icon) :
                                                            "-"
                                                        : "-----"
                                                }
                                            </span>
                                        </div>
                                        {wallets.find((e) => e.currency === el.currency)?.available_balance && <button onClick={toggleShowBalance} className='p-2'>
                                            <FaEyeSlash />
                                        </button>}
                                    </div>
                                    {wallets.find((e) => e.currency === el.currency)?.available_balance && wallets.find((e) => e.currency === el.currency)!.available_balance.length > 6 &&
                                        <>
                                            {
                                                unavailableWallets.includes(el.currency) ?
                                                    <div className='text-[11px] bg-yellow-600 bg-opacity-70 px-2 py-1 rounded-xl text-neutral-100 font-bold'>Coming soon</div> :
                                                    <AddFundsModal activeWallet={activeWallet}><button className='text-xs px-3 py-1 rounded-lg bg-neutral-800 bg-opacity-50 shadow-lg mt-2'>Add Funds</button></AddFundsModal>
                                            }
                                        </>}
                                </div>
                                {!wallets.find((e) => e.currency === el.currency) &&
                                    <>
                                        {
                                            unavailableWallets.includes(el.currency) ?
                                                <div className='text-[11px] bg-yellow-600 bg-opacity-70 px-2 py-1 rounded-xl text-neutral-100 font-bold'>Coming soon</div> :
                                                <CreateWallet activeWallet={activeWallet}><button className='text-xs px-3 py-1 rounded-lg bg-neutral-800  shadow-lg'>Create wallet</button></CreateWallet>
                                        }
                                    </>
                                }

                                {wallets.find((e) => e.currency === el.currency)?.available_balance && wallets.find((e) => e.currency === el.currency)!.available_balance.length < 7 &&
                                    <>
                                        {
                                            unavailableWallets.includes(el.currency) ?
                                                <div className='text-[11px] bg-yellow-600 bg-opacity-70 px-2 py-1 rounded-xl text-neutral-100 font-bold'>Coming soon</div> :
                                                <AddFundsModal activeWallet={activeWallet}><button className='text-xs px-3 py-1 rounded-lg bg-neutral-800 bg-opacity-50 shadow-lg'>Add Funds</button></AddFundsModal>
                                        }
                                    </>}
                            </div>

                            <div className='absolute bottom-[40px]'>
                                {<CustomWalletSelectButton activeWallet={activeWallet} walletConfig={walletConfig} isActive={activeWallet === i} />}
                            </div>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>


        </>
    )
}

export default WalletSlider