import React, { useMemo, useState } from 'react'
import { GiWallet } from "react-icons/gi";
import { IoSendSharp } from 'react-icons/io5';
// import { HiUsers } from 'react-icons/hi';
import { GrTransaction } from 'react-icons/gr';
import WalletSlider from '@/components/WalletSlider';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { walletList } from '@/utils/constants';
import AddFundsModal from '@/components/modals/AddFundsModal';
import { useToast } from '@chakra-ui/react';
import { useApp } from '@/contexts/appContext';

function Wallet() {

    const navigate = useNavigate();
    const toast = useToast();
    const { user, wallets } = useApp();

    const [searchParams] = useSearchParams();
    const urlWallet = useMemo(() => {
        const w = searchParams.get('wallet');
        if (w) {
            if (walletList.includes(w)) {
                return w
            } else {
                return "ngn";
            }
        } else {
            return "ngn";
        }
    }, [searchParams]);

    const [activeWallet, setActiveWallet] = useState(urlWallet);

    function handleNavigate(url: string) {
        const getBvnKycInfo = user!.kyc.details.find((e) => e.parameter === "bvn")!;
        if (getBvnKycInfo.status === "Pending") {
            toast({
                title: 'Error',
                description: 'Complete kyc to continue',
                status: 'warning',
                duration: 5000,
                isClosable: true,
                position: 'top'
            })
        } else {
            if (!wallets.map(e => e.currency.toLowerCase()).includes(activeWallet)) {
                toast({
                    title: 'Error',
                    description: 'Kindly create wallet',
                    status: 'warning',
                    duration: 5000,
                    isClosable: true,
                    position: 'top'
                })
            } else {
                navigate(url);
            }
        }

    }

    return (
        <>
            <div className='pt-8 md:pt-7'>
                <div className='mb-10 px-6'>
                    <div className='font-extrabold text-xl'>Wallet</div>
                </div>

                <div className='px-6'>
                    <div className='flex justify-start'>
                        <div className='w-full md:w-[400px]'>
                            <div className='w-full mb-[28px] px-5'>
                                <WalletSlider onSliderChange={(e) => setActiveWallet(e)} size='full' />
                            </div>

                            <div className='flex justify-center mb-[48px] px-0 md:px-5'>
                                <div className='grid grid-cols-3 items-center gap-x-[5px] gap-y-[25px]'>

                                    <div className='flex justify-center'>
                                        <AddFundsModal activeWallet={walletList.indexOf(activeWallet)}>
                                            <button>
                                                <center>
                                                    <div className='inline-block justify-center items-center px-3 py-3 md:px-4 md:py-4 border-2 border-purple-600 mb-2 border-opacity-15 shadow-lg bg-white dark:bg-neutral-800 rounded-full'>
                                                        <GiWallet className='w-5 h-5 text-neutral-950 dark:text-purple-50 dark:text-opacity-95' />
                                                    </div>
                                                    <div className='text-[13px] font-extrabold text-neutral-950 dark:text-purple-50'>Add Funds</div>
                                                </center>
                                            </button>
                                        </AddFundsModal>
                                    </div>

                                    <div className='flex justify-center'>
                                        <button className='w-full' onClick={() => handleNavigate(`/u/pay/send?wallet=${activeWallet}`)}>
                                            <center>
                                                <div className='inline-block justify-center items-center px-3 py-3 md:px-4 md:py-4 border-2 border-purple-600 mb-2 border-opacity-15 shadow-lg bg-white dark:bg-neutral-800 rounded-full'>

                                                    <IoSendSharp className='w-5 h-5 text-neutral-950 dark:text-purple-50 dark:text-opacity-95' />
                                                </div>
                                                <div className='text-[13px] font-extrabold text-neutral-950 dark:text-purple-50'>Send</div>
                                            </center>
                                        </button>
                                    </div>

                                    <div className='flex justify-center'>
                                        <button className='w-full' onClick={() => handleNavigate(`/u/transactions?wallet=${activeWallet}`)}>
                                            <center>
                                                <div className='inline-block justify-center items-center px-3 py-3 md:px-4 md:py-4 border-2 border-purple-600 mb-2 border-opacity-15 shadow-lg bg-white dark:bg-neutral-800 rounded-full'>

                                                    <GrTransaction className='w-5 h-5 text-neutral-950 dark:text-purple-50 dark:text-opacity-95' />
                                                </div>
                                                <div className='text-[13px] font-extrabold text-neutral-950 dark:text-purple-50'>Transactions</div>
                                            </center>
                                        </button>
                                    </div>

                                    {/* <div className='flex justify-center'>
                                        <button className='w-full' onClick={() => handleNavigate(`/u/beneficiaries?wallet=${activeWallet}`)}>
                                            <center>
                                                <div className='inline-block justify-center items-center px-3 py-3 md:px-4 md:py-4 border-2 border-purple-600 mb-2 border-opacity-15 shadow-lg bg-white dark:bg-neutral-800 rounded-full'>

                                                    <HiUsers className='w-5 h-5 text-neutral-950 dark:text-purple-50 dark:text-opacity-95' />
                                                </div>
                                                <div className='text-[13px] font-extrabold text-neutral-950 dark:text-purple-50'>Beneficiaries</div>
                                            </center>
                                        </button>
                                    </div> */}


                                </div>
                            </div>

                            {/* <div className='mb-10 px-6'>
                                <div className='font-extrabold text-md'>Transactions</div>
                            </div> */}



                        </div>
                        <div className='hidden md:inline-block'>
                            {/* <div className='text-sm mb-1'>Wallet ID</div>
                            <div className='flex gap-2 items-center'>
                                <div className='font-extrabold text-2xl'></div>
                                <div></div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Wallet