import CustomInput from '@/components/CustomInput';
import CustomSelect from '@/components/CustomSelect';
import http from '@/lib/http';
import { IBankInfo } from '@/types/global.types';
import { handleResponseError } from '@/utils/errors';
import { useToast } from '@chakra-ui/react';
import classNames from 'classnames';
import React, { useEffect, useMemo, useState } from 'react'
import { CgSpinner } from 'react-icons/cg';
import { FaSpinner } from 'react-icons/fa6';
import { IoArrowBackCircleOutline } from "react-icons/io5";
import { useNavigate, useSearchParams } from 'react-router-dom';

function AddBeneficiaries() {

  const navigate = useNavigate();
  const toast = useToast();

  const [searchParams, setSearchParams] = useSearchParams();
  const urlTab = useMemo(() => {
    const t = searchParams.get("type");
    if (!t) return "external";
    if (["external", "internal"].includes(t)) {
      return t;
    } else {
      return "external"
    }
  }, [searchParams])

  const [beneficiaryTab, setBeneficiaryTab] = useState<"external" | "internal">(urlTab as any);
  const [addExternalBeneficiaryInput, setAddExternalBeneficiaryInput] = useState({
    currency: "NGN",
    code: "",
    number: ""
  });
  const [addInternalBeneficiaryInput, setAddInternalBeneficiaryInput] = useState({
    currency: "NGN",
    wallet_id: "",
  });
  const [isFetching, setIsFetching] = useState(false);
  const [isFetchingBanks, setIsFetchingBanks] = useState(false);
  const [banks, setBanks] = useState<IBankInfo[]>([
    {
      "id": "53",
      "code": "000014",
      "name": "Access Bank",
      "country": "ng",
      "currency": "ngn",
      "image": "",
      "type": "bank",
      "destination": "bank_account",
      "operation": "financials"
    }
  ]);

  function changeTab(tab: any) {
    setBeneficiaryTab(tab);
    setSearchParams({ type: tab });
  }

  async function getBanks() {
    setIsFetchingBanks(true);
    try {
      const resp = await http<IBankInfo[]>(`wallet/transfer/external/${addExternalBeneficiaryInput.currency.toLowerCase()}/recipient/institutions`);
      resp.data && setBanks(resp.data);
      setIsFetchingBanks(false);
    } catch (error) {
      console.log(error);
      setIsFetchingBanks(false);
      handleResponseError(error, toast);
    }
  }

  async function addExternalBeneficiary() {
    setIsFetching(true);
    console.log("addExternalBeneficiaryInput", addExternalBeneficiaryInput);
    try {
      const resp = await http(`wallet/transfer/external/${addExternalBeneficiaryInput.currency.toLowerCase()}/recipient/single/add`, { ...addExternalBeneficiaryInput });
      console.log(resp.data);
      toast({
        title: 'Success',
        description: resp.message,
        status: 'success',
        duration: 5000,
        isClosable: true,
      })
      setIsFetching(false);
      navigate(`/u/beneficiaries?wallet=${addExternalBeneficiaryInput.currency}&type=external`);
    } catch (error) {
      console.log(error);
      setIsFetching(false);
      handleResponseError(error, toast);
    }
  }

  async function addInternalBeneficiary() {
    setIsFetching(true);
    console.log("addInternalBeneficiaryInput", addInternalBeneficiaryInput);
    try {
      const resp = await http(`wallet/transfer/internal/${addInternalBeneficiaryInput.currency.toLowerCase()}/recipient/single/add`, { identifier:addInternalBeneficiaryInput.wallet_id });
      console.log(resp.data);
      toast({
        title: 'Success',
        description: resp.message,
        status: 'success',
        duration: 5000,
        isClosable: true,
      })
      setIsFetching(false);
      navigate(`/u/beneficiaries?wallet=${addInternalBeneficiaryInput.currency}&type=internal`);
    } catch (error) {
      console.log(error);
      setIsFetching(false);
      handleResponseError(error, toast);
    }
  }

  useEffect(() => {
    getBanks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const isAddExternalBeneficiaryFormValid = useMemo(() => {
    if (
      addExternalBeneficiaryInput.code.length > 0 &&
      addExternalBeneficiaryInput.currency.length > 0 &&
      addExternalBeneficiaryInput.number.length > 0) {
      return true
    } else {
      return false;
    }
  }, [addExternalBeneficiaryInput]);

  const isAddInternalBeneficiaryFormValid = useMemo(() => {
    if (
      addInternalBeneficiaryInput.currency.length > 0 &&
      addInternalBeneficiaryInput.wallet_id.length > 0) {
      return true
    } else {
      return false;
    }
  }, [addInternalBeneficiaryInput]);

  return (
    <>
      <div className='pt-8 md:pt-7'>

        <div className='px-6'>
          <div className='block md:flex w-full justify-center mt-0 md:mt-[100px]'>
            <div className='bg-none md:bg-white dark:bg-none dark:md:bg-neutral-800 px-0 py-0 md:px-[60px] md:py-[50px] w-full lg:w-[70%] rounded-xl'>

              <div className='text-black dark:text-neutral-100 '>

                <div className='mb-10 -ml-1'>
                  <div className='flex items-center gap-5'>
                    <button className='' onClick={() => navigate(-1)}>
                      <IoArrowBackCircleOutline className="w-8 h-8" />
                    </button>
                    <div className='font-extrabold text-xl'>Add Beneficiary</div>
                  </div>
                </div>

                <div className='mb-10 px-0'>
                  <div className='flex justify-between'>
                    <div className='flex items-center gap-3'>
                      <button onClick={() => !isFetching && changeTab("external")} className={classNames('rounded-full py-1 px-5 text-sm/6 font-semibold dark:text-white focus:outline-none dark:hover:bg-white/5 hover:bg-slate-200 focus:outline-3 focus:outline-purple-700', {
                        'outline-none outline-3 outline-purple-700': beneficiaryTab === "external",
                        '': beneficiaryTab !== "external"
                      })}>Bank</button>
                      <button onClick={() => !isFetching && changeTab("internal")} className={classNames('rounded-full py-1 px-5 text-sm/6 font-semibold dark:text-white focus:outline-none dark:hover:bg-white/5 hover:bg-slate-200 focus:outline-3 focus:outline-purple-700', {
                        'outline-none outline-3 outline-purple-700': beneficiaryTab === "internal",
                        '': beneficiaryTab !== "internal"
                      })}>Orion Pay</button>
                    </div>
                  </div>
                </div>


                {
                  beneficiaryTab === "external" ?
                    <>
                      <div className='block md:flex justify-center w-full'>
                        <div className=' w-full md:w-[300px] lg:w-[400px] px-0 md:px-5'>

                          <div className='mb-6'>
                            <div className='text-sm font-normal mb-1'> <span className='text-red-600'>*</span> Currency</div>
                            <CustomSelect
                              value={addExternalBeneficiaryInput.currency}
                              options={["NGN", "USD", "EUR", "GBP"]}
                              onChange={(e) => setAddExternalBeneficiaryInput((i) => ({ ...i, currency: e }))}
                              placeholder='Select'
                              disabled={isFetching}
                            />
                          </div>

                          <div className='mb-6'>

                            <div className='text-sm font-normal mb-1 flex gap-1 items-center'>
                              <span className='text-red-600'>*</span>
                              <div className='flex items-center gap-2'>
                                <span>Bank</span>
                                {isFetchingBanks && <CgSpinner className='animate-spin' />}
                              </div>
                            </div>
                            <CustomSelect
                              value={addExternalBeneficiaryInput.code}
                              options={banks}
                              onChange={(e) => setAddExternalBeneficiaryInput((i) => ({ ...i, code: e }))}
                              placeholder='Select'
                              display={{
                                title: "name",
                                value: "code"
                              }}
                              disabled={isFetching || isFetchingBanks}
                            />
                          </div>

                          <div className='mb-10'>
                            <div className='text-sm font-normal mb-1'> <span className='text-red-600'>*</span> Account number</div>
                            <CustomInput
                              type='number'
                              name='account_number'
                              value={addExternalBeneficiaryInput.number}
                              onChange={(e) => setAddExternalBeneficiaryInput((i) => ({ ...i, number: e }))}
                              placeholder=''
                              disabled={isFetching}
                            />
                          </div>

                          <div className='mb-5'>
                            <button onClick={() => addExternalBeneficiary()} disabled={isFetching || !isAddExternalBeneficiaryFormValid} className='justify-center flex w-full text-sm text-neutral-100 font-bold bg-purple-800 shadow-md dark:bg-purple-700 px-5 py-4 rounded-lg disabled:bg-opacity-50 dark:disabled:disabled:bg-opacity-20'>
                              {
                                isFetching ?
                                  <FaSpinner className='animate-spin' /> :
                                  <span>Add Beneficiary</span>
                              }
                            </button>
                          </div>
                        </div>
                      </div>
                    </> :
                    <>
                      <div className='block md:flex justify-center w-full'>
                        <div className=' w-full md:w-[300px] lg:w-[400px] px-0 md:px-5'>

                          <div className='mb-6'>
                            <div className='text-sm font-normal mb-1'> <span className='text-red-600'>*</span> Currency</div>
                            <CustomSelect
                              value={addInternalBeneficiaryInput.currency}
                              options={["NGN", "USD", "EUR", "GBP"]}
                              onChange={(e) => setAddInternalBeneficiaryInput((i) => ({ ...i, currency: e }))}
                              placeholder='Select'
                              disabled={isFetching}
                            />
                          </div>

                          <div className='mb-6'>
                            <div className='text-sm font-normal mb-1'> <span className='text-red-600'>*</span> Wallet ID </div>
                            <CustomInput
                              type='text'
                              name='wallet_id'
                              value={addInternalBeneficiaryInput.wallet_id}
                              onChange={(e) => setAddInternalBeneficiaryInput((i) => ({ ...i, wallet_id: e }))}
                              placeholder='Email, Phone or Wallet ID'
                              disabled={isFetching}
                            />
                          </div>

                          <div className='mb-5'>
                            <button onClick={() => addInternalBeneficiary()} disabled={isFetching || !isAddInternalBeneficiaryFormValid} className='justify-center flex w-full text-sm text-neutral-100 font-bold bg-purple-800 shadow-md dark:bg-purple-700 px-5 py-4 rounded-lg disabled:bg-opacity-50 dark:disabled:disabled:bg-opacity-20'>
                              {
                                isFetching ?
                                  <FaSpinner className='animate-spin' /> :
                                  <span>Add Beneficiary</span>
                              }
                            </button>
                          </div>
                        </div>
                      </div>
                    </>
                }

              </div>

            </div>
          </div>
        </div>

        <div className='py-8'></div>
      </div>
    </>
  )
}

export default AddBeneficiaries