
export const walletConfig = [
    {
        currency: "ngn",
        color: "bg-teal-900",
        icon: "₦"
    },
    {
        currency: "usd",
        color: "bg-blue-700",
        icon: "$"
    },
    {
        currency: "eur",
        color: "bg-blue-950",
        icon: "€"
    },
    {
        currency: "gbp",
        color: "bg-sky-600",
        icon: "£"
    },
]

export const walletList = walletConfig.map((e) => e.currency);

export const unavailableWallets = ["usd", "eur", "gbp"];