import { useApp } from '@/contexts/appContext';
import http from '@/lib/http';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react'
import { BiCopy } from 'react-icons/bi';
import { IoArrowBackCircleOutline } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Hide,
  Show,
  useToast,
  Skeleton,
} from '@chakra-ui/react'
import {
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from '@chakra-ui/react'
import CustomSelect from '@/components/CustomSelect';
import { CgSpinner } from 'react-icons/cg';
import { handleResponseError } from '@/utils/errors';
import { useTheme } from '@/contexts/themeProvider';
import { BsViewList } from 'react-icons/bs';

function PayFundWithBankTransfer() {

  const { resolvedTheme } = useTheme();
  const navigate = useNavigate();
  const { wallets } = useApp();
  const [walletsTab, setWalletsTab] = useState<"ngn" | "usd" | "eur" | "gbp">("ngn");

  const { isOpen: isCreateBankAccountModalOpen, onOpen: onCreateBankAccountModalOpen, onClose: onCreateBankAccountModalClose } = useDisclosure()
  const toast = useToast();

  const [isFetching, setIsFetching] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [bankAccounts, setBankAccounts] = useState<{
    reference_code: string,
    number: string,
    bank: string,
    code: string,
    name: string
  }[]>([]);
  const [supportedBanks, setSupportedBanks] = useState<{
    bank: string
  }[]>([]);

  const [createBankInput, setCreateBankInput] = useState({
    bank: ""
  });

  async function getDetails(filter: object = {}) {
    setIsFetching(true)
    try {
      const resp = await Promise.all([
        http(`service/bankaccount/${walletsTab}/details`),
        http(`service/bankaccount/${walletsTab}/supported-bank`)
      ]);
      resp[0].data ? setBankAccounts(resp[0].data as any) : setBankAccounts([]);
      resp[1].data ? setSupportedBanks(resp[1].data as any) : setSupportedBanks([]);
      // console.log("FIlter:", { currency: walletsTab, ...filter })
      setIsFetching(false)
    } catch (error) {
      console.log(error);
      setIsFetching(false)
      // handleResponseError(error, toast);
    }
  }

  useEffect(() => {
    getDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [walletsTab]);

  function handleOnCreateBankAccountModalClose() {
    setCreateBankInput({
      bank: ""
    })
    onCreateBankAccountModalClose();
  }

  async function createBankAccount() {
    setIsSending(true);
    try {
      const resp = await http<{
        reference_code: string,
        number: string,
        bank: string,
        code: string,
        name: string
      }>(`service/bankaccount/${walletsTab}/create-user-base`, { ...createBankInput });
      console.log("create Resp:", resp.data);
      if (resp.data) {
        const _b = resp.data;
        setBankAccounts((e) => [...e, {
          reference_code: _b.reference_code,
          name: _b.name,
          bank: _b.bank,
          number: _b.number,
          code: _b.code,
        }])
      }
      handleOnCreateBankAccountModalClose();
      setIsSending(false);
    } catch (error) {
      console.log(error);
      setIsSending(false);
      handleResponseError(error, toast);

    }
  }

  return (
    <>
      <div className='pt-8 md:pt-7'>
        <div className='px-6'>
          <div className='block md:flex w-full justify-center mt-0 md:mt-[100px]'>
            <div className='bg-none md:bg-white dark:bg-none dark:md:bg-neutral-800 px-2 py-2 md:px-[60px] md:py-[50px] w-full lg:w-[70%] rounded-xl'>

              <div className='text-black dark:text-neutral-100 '>

                <div className='mb-10'>
                  <div className='flex items-center gap-5'>
                    <button className='' onClick={() => navigate(-1)}>
                      <IoArrowBackCircleOutline className="w-8 h-8" />
                    </button>
                    <div className='font-extrabold text-xl'>Fund With Bank Transfer</div>
                  </div>
                </div>

                <div className='mb-10 pb-5 px-0 md:px-6 border-b-2'>
                  <div className='flex justify-between'>
                    <div className='flex items-center gap-2'>
                      {wallets.map((e) => e.currency.toLowerCase()).includes("ngn") && <button onClick={() => setWalletsTab("ngn")} className={classNames('md:flex gap-2 items-center rounded-full py-1 px-5 text-sm/6 font-semibold dark:text-white focus:outline-none dark:hover:bg-white/5 hover:bg-slate-200 focus:outline-3 focus:outline-purple-700', {
                        'outline-none outline-3 outline-purple-700': walletsTab === "ngn",
                        '': walletsTab !== "ngn"
                      })}> <img src="/assets/flags/ngn.svg" className='w-5 inline-block' alt="" /> <div className='md:pr-0'>NGN</div></button>}
                      {wallets.map((e) => e.currency.toLowerCase()).includes("usd") && <button onClick={() => setWalletsTab("usd")} className={classNames('md:flex gap-2 items-center rounded-full py-1 px-5 text-sm/6 font-semibold dark:text-white focus:outline-none dark:hover:bg-white/5 hover:bg-slate-200 focus:outline-3 focus:outline-purple-700', {
                        'outline-none outline-3 outline-purple-700': walletsTab === "usd",
                        '': walletsTab !== "usd"
                      })}> <img src="/assets/flags/usd.svg" className='w-5 inline-block' alt="" /> <div className='md:pr-0'>USD</div></button>}
                      {wallets.map((e) => e.currency.toLowerCase()).includes("eur") && <button onClick={() => setWalletsTab("eur")} className={classNames('md:flex gap-2 items-center rounded-full py-1 px-5 text-sm/6 font-semibold dark:text-white focus:outline-none dark:hover:bg-white/5 hover:bg-slate-200 focus:outline-3 focus:outline-purple-700', {
                        'outline-none outline-3 outline-purple-700': walletsTab === "eur",
                        '': walletsTab !== "eur"
                      })}> <img src="/assets/flags/eur.svg" className='w-5 inline-block' alt="" /> <div className='md:pr-0'>EUR</div></button>}
                      {wallets.map((e) => e.currency.toLowerCase()).includes("gbp") && <button onClick={() => setWalletsTab("gbp")} className={classNames('md:flex gap-2 items-center rounded-full py-1 px-5 text-sm/6 font-semibold dark:text-white focus:outline-none dark:hover:bg-white/5 hover:bg-slate-200 focus:outline-3 focus:outline-purple-700', {
                        'outline-none outline-3 outline-purple-700': walletsTab === "gbp",
                        '': walletsTab !== "gbp"
                      })}> <img src="/assets/flags/gbp.svg" className='w-5 inline-block' alt="" /> <div className='md:pr-0'>GBP</div></button>}
                    </div>
                  </div>
                </div>

                <div className='px-0 mb-6 flex justify-between items-center gap-10'>
                  <div className='text-xs'>
                    <div>
                      You can make a bank transfer to the bank accounts below.
                    </div>
                  </div>

                  <div>
                    <button onClick={() => onCreateBankAccountModalOpen()} className='text-xs font-bold bg-purple-700 text-neutral-50 px-2 py-1 rounded-md'>Create account</button>
                  </div>
                </div>

                <div>

                  {!isFetching ?
                    bankAccounts.length > 0 ?
                      bankAccounts.map((t, i) => (
                        <div key={i} className='px-5 md:px-5 mb-6 py-3 rounded-lg bg-slate-100 dark:bg-neutral-700'>
                          <div className='text-sm text-neutral-700 dark:text-neutral-200 md:w-[300px] mb-1'>{t.bank}</div>
                          <div className=' flex items-center gap-3 mb-1'>
                            <div className='font-extrabold text-sm'>{t.number}</div>
                            <div><BiCopy /></div>
                          </div>
                          <div className='text-xs mb-1'>{t.name}</div>
                        </div>)) :
                      <div className='w-full h-[250px] flex justify-center px-5 items-center'>
                        <div className='text-slate-900 dark:text-neutral-200 text-opacity-70 dark:text-opacity-70 font-light'>
                          <center>
                            <div className='mb-5 text-lg'>No record</div>
                            <BsViewList className='text-[30px]' />
                          </center>
                        </div>
                      </div>
                    :
                    Array.from(Array(5)).map((_, i) => (
                      <div className='flex justify-between gap-3 items-center mb-3' key={i}>
                        <div className='flex gap-3 items-center'>
                          {/* <Skeleton
                            startColor={resolvedTheme === "dark" ? `whiteAlpha.400` : `gray.300`}
                            endColor={resolvedTheme === "dark" ? `whiteAlpha.100` : `gray.100`}
                            w={'50px'} h={'50px'} borderRadius={'10%'} /> */}
                          <div>
                            <Skeleton
                              startColor={resolvedTheme === "dark" ? `whiteAlpha.400` : `gray.300`}
                              endColor={resolvedTheme === "dark" ? `whiteAlpha.100` : `gray.100`}
                              w={'150px'} h={'14px'} mb={1} borderRadius={'10%'} />
                            <Skeleton
                              startColor={resolvedTheme === "dark" ? `whiteAlpha.400` : `gray.300`}
                              endColor={resolvedTheme === "dark" ? `whiteAlpha.100` : `gray.100`}
                              w={'60px'} h={'10px'} mb={1} borderRadius={'10%'} />
                            <Skeleton
                              startColor={resolvedTheme === "dark" ? `whiteAlpha.400` : `gray.300`}
                              endColor={resolvedTheme === "dark" ? `whiteAlpha.100` : `gray.100`}
                              w={'80px'} h={'10px'} mb={1} borderRadius={'10%'} />
                          </div>
                        </div>
                        {/* <div>
                          <Skeleton
                            startColor={resolvedTheme === "dark" ? `whiteAlpha.400` : `gray.300`}
                            endColor={resolvedTheme === "dark" ? `whiteAlpha.100` : `gray.100`}
                            w={'40px'} h={'10px'} mb={1} borderRadius={'10%'} />
                        </div> */}
                      </div>
                    ))
                  }

                </div>

              </div>

            </div>
          </div>
        </div>

        <div className='py-8'></div>
      </div>

      <Hide below='md'>
        <Modal isOpen={isCreateBankAccountModalOpen} onClose={handleOnCreateBankAccountModalClose} closeOnOverlayClick={false}>
          <ModalOverlay />
          <ModalContent>
            {/* <ModalHeader>Title</ModalHeader> */}
            <ModalCloseButton className='text-neutral-800 dark:text-white' />
            <ModalBody className='text-neutral-800 dark:text-white bg-neutral-100 dark:bg-neutral-900'>
              <div className='py-8'>
                <div className='flex mb-8 items-center gap-3'>
                  <div className='font-extrabold text-2xl'>Create bank account</div>
                </div>

                <div>

                  <div className='mb-5'>
                    <div className='text-sm font-normal mb-1'> <span className='text-red-600'>*</span> Bank</div>
                    <CustomSelect
                      value={createBankInput.bank}
                      options={supportedBanks}
                      onChange={(e) => setCreateBankInput((i) => ({ ...i, bank: e }))}
                      placeholder='Select bank'
                      display={{
                        title: "bank",
                        value: "bank"
                      }}
                      disabled={isSending || isFetching}
                    />
                  </div>

                  <div className='mb-5'>
                    <button onClick={() => createBankAccount()} disabled={isSending || isFetching || createBankInput.bank === ""} className='justify-center flex w-full text-sm text-neutral-100 font-bold bg-purple-800 shadow-md dark:bg-purple-700 px-5 py-4 rounded-lg disabled:bg-opacity-50 dark:disabled:disabled:bg-opacity-20'>
                      {
                        isSending ?
                          <CgSpinner className='animate-spin' /> :
                          <span>Submit</span>
                      }
                    </button>
                  </div>

                </div>

                <div className='py-[10px]'></div>
              </div>
            </ModalBody>
          </ModalContent>
        </Modal>
      </Hide>

      <Show below='md'>
        <Drawer placement={"bottom"} isOpen={isCreateBankAccountModalOpen} onClose={handleOnCreateBankAccountModalClose} closeOnOverlayClick={false}>
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton className='text-neutral-800 dark:text-white' />
            <DrawerBody className='text-neutral-800 dark:text-white bg-neutral-100 dark:bg-neutral-900'>
              <div className='py-8'>
                <div className='flex mb-8 items-center gap-3'>
                  <div className='font-extrabold text-2xl'>Create bank account</div>
                </div>

                <div>

                  <div className='mb-5'>
                    <div className='text-sm font-normal mb-1'> <span className='text-red-600'>*</span> Bank</div>
                    <CustomSelect
                      value={createBankInput.bank}
                      options={supportedBanks}
                      onChange={(e) => setCreateBankInput((i) => ({ ...i, bank: e }))}
                      placeholder='Select bank'
                      display={{
                        title: "bank",
                        value: "bank"
                      }}
                      disabled={isSending || isFetching}
                    />
                  </div>

                  <div className='mb-5'>
                    <button onClick={() => createBankAccount()} disabled={isSending || isFetching || createBankInput.bank === ""} className='justify-center flex w-full text-sm text-neutral-100 font-bold bg-purple-800 shadow-md dark:bg-purple-700 px-5 py-4 rounded-lg disabled:bg-opacity-50 dark:disabled:disabled:bg-opacity-20'>
                      {
                        isSending ?
                          <CgSpinner className='animate-spin' /> :
                          <span>Submit</span>
                      }
                    </button>
                  </div>

                </div>

                <div className='py-[40px]'></div>
              </div>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </Show>
    </>
  )
}

export default PayFundWithBankTransfer