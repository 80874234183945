import { useTheme } from '@/contexts/themeProvider';
import http from '@/lib/http';
import { handleResponseError } from '@/utils/errors';
import { Button, Skeleton, useDisclosure, useToast } from '@chakra-ui/react';
import classNames from 'classnames';
import React, { useEffect, useMemo, useState } from 'react'
import { BiPlusCircle } from 'react-icons/bi';
import { FaUser } from 'react-icons/fa6';
import { Link, useSearchParams } from 'react-router-dom';
import { BsViewList } from "react-icons/bs";
import { walletList } from '@/utils/constants';
import { PiTrashSimpleBold } from "react-icons/pi";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  // AlertDialogCloseButton,
} from '@chakra-ui/react'
import { CgSpinner } from 'react-icons/cg';
import { IBeneficiary } from '@/types/global.types';
import CustomInput from '@/components/CustomInput';

function Beneficiaries() {

  const toast = useToast();
  const { resolvedTheme } = useTheme();
  const { isOpen: isConfirmOpen, onOpen: onConfirmOpen, onClose: onConfirmClose } = useDisclosure()
  const cancelRef = React.createRef<HTMLButtonElement>()

  const [searchParams, setSearchParams] = useSearchParams();
  const [searchInput, setSearchInput] = useState("");
  const urlTab = useMemo(() => {
    const t = searchParams.get("type");
    if (!t) return "external";
    if (["external", "internal"].includes(t)) {
      return t;
    } else {
      return "external"
    }
  }, [searchParams])

  const urlWallet = useMemo(() => {
    const t = searchParams.get("wallet");
    if (!t) return "ngn";
    if (walletList.includes(t)) {
      return t;
    } else {
      return "ngn"
    }
  }, [searchParams])

  const [walletsTab, setWalletsTab] = useState<"ngn" | "usd" | "eur" | "gbp">(urlWallet as any);
  const [beneficiaryTab, setBeneficiaryTab] = useState<"external" | "internal">(urlTab as any);
  const [externalBeneficiaries, setExternalBeneficiaries] = useState<IBeneficiary[]>([]);
  const [internalBeneficiaries, setInternalBeneficiaries] = useState<IBeneficiary[]>([]);
  const [isFetching, setIsFetching] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteRecipientId, setDeleteRecipientId] = useState("");

  async function getBeneficiaries() {
    setIsFetching(true)
    try {
      const resp = await Promise.all([
        http(`wallet/transfer/external/${walletsTab}/recipient/details`),
        http(`wallet/transfer/internal/${walletsTab}/recipient/details`)
      ]);
      resp[0].data ? setExternalBeneficiaries(resp[0].data as any) : setExternalBeneficiaries([]);
      resp[1].data ? setInternalBeneficiaries(resp[1].data as any) : setInternalBeneficiaries([]);
      // resp && setExternalBeneficiaries([
      //   {
      //     "recipient_id": "0a5c79b1eaf15445da252ada718857e9",
      //     "number": "0126069407",
      //     "bank": "Gtbank Plc",
      //     "code": "000013",
      //     "name": "Bakare Abdulquadry Olajide"
      //   }
      // ])
      setIsFetching(false)
    } catch (error) {
      console.log(error);
      setIsFetching(false);
      setExternalBeneficiaries([]);
      setInternalBeneficiaries([]);
      handleResponseError(error, toast);
    }
  }

  useEffect(() => {
    getBeneficiaries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [walletsTab])

  function changeTab(tab: any) {
    setBeneficiaryTab(tab);
    setSearchParams({ type: tab, wallet: urlWallet });
  }

  function changeWalletTab(tab: any) {
    setWalletsTab(tab);
    setSearchParams({ wallet: tab, type: urlTab });
  }

  async function handleDelete(recipient_id: string) {
    setDeleteRecipientId(recipient_id);
    onConfirmOpen();
  }
  async function handleDeleteCancel() {
    setDeleteRecipientId("");
    onConfirmClose();
  }

  async function handleConfirmDelete() {
    setIsDeleting(true);
    try {
      const resp = await http(`wallet/transfer/${beneficiaryTab}/${walletsTab}/recipient/single/remove`, { recipient_id: deleteRecipientId });
      setExternalBeneficiaries((b) => b.filter((e) => e.recipient_id !== deleteRecipientId));
      toast({
        title: 'Success',
        description: resp.message,
        status: 'success',
        duration: 5000,
        isClosable: true,
      })
      if (beneficiaryTab === "internal") {
        setInternalBeneficiaries((b) => ([...b].filter((f) => f.recipient_id !== deleteRecipientId)))
      } else {
        setExternalBeneficiaries((b) => ([...b].filter((f) => f.recipient_id !== deleteRecipientId)))
      }
      setDeleteRecipientId("");
      setIsDeleting(false);
      onConfirmClose();
    } catch (error) {
      console.log(error);
      setIsDeleting(false)
      handleResponseError(error, toast);
    }
  }

  const filteredOptionsExternal = useMemo(() => {
    if (searchInput.length === 0) return externalBeneficiaries;
    if (externalBeneficiaries.length === 0) return externalBeneficiaries;
    return externalBeneficiaries.filter((e) => {
      return `${e.name}`.toLowerCase().includes(searchInput.toLowerCase()) || `${e.number}`.toLowerCase().includes(searchInput.toLowerCase()) || `${e.bank}`.toLowerCase().includes(searchInput.toLowerCase());
    })
  }, [searchInput, externalBeneficiaries]);

  const filteredOptionsInternal = useMemo(() => {
    if (searchInput.length === 0) return internalBeneficiaries;
    if (internalBeneficiaries.length === 0) return internalBeneficiaries;
    return internalBeneficiaries.filter((e) => {
      return `${e.name}`.toLowerCase().includes(searchInput.toLowerCase());
    })
  }, [searchInput, internalBeneficiaries]);

  return (
    <>
      <div className='pt-8 md:pt-7'>
        <div className='mb-10 px-6'>
          <div className='font-extrabold text-xl'>Beneficiaries</div>
        </div>

        <div className='mb-5 pb-5 px-6'>
          <div className='flex justify-between'>
            <div className='flex items-center gap-2'>
              <button onClick={() => changeWalletTab("ngn")} className={classNames('md:flex gap-2 items-center rounded-md md:rounded-full py-1 px-5 text-sm/6 font-semibold dark:text-white focus:outline-none dark:hover:bg-white/5 hover:bg-slate-200 focus:outline-3 focus:outline-purple-700', {
                'outline-none outline-3 outline-purple-700': walletsTab === "ngn",
                '': walletsTab !== "ngn"
              })}> <img src="/assets/flags/ngn.svg" className='w-5 inline-block' alt="" /> <div className='md:pr-0'>NGN</div></button>
              <button onClick={() => changeWalletTab("usd")} className={classNames('md:flex gap-2 items-center rounded-md md:rounded-full py-1 px-5 text-sm/6 font-semibold dark:text-white focus:outline-none dark:hover:bg-white/5 hover:bg-slate-200 focus:outline-3 focus:outline-purple-700', {
                'outline-none outline-3 outline-purple-700': walletsTab === "usd",
                '': walletsTab !== "usd"
              })}> <img src="/assets/flags/usd.svg" className='w-5 inline-block' alt="" /> <div className='md:pr-0'>USD</div></button>
              <button onClick={() => changeWalletTab("eur")} className={classNames('md:flex gap-2 items-center rounded-md md:rounded-full py-1 px-5 text-sm/6 font-semibold dark:text-white focus:outline-none dark:hover:bg-white/5 hover:bg-slate-200 focus:outline-3 focus:outline-purple-700', {
                'outline-none outline-3 outline-purple-700': walletsTab === "eur",
                '': walletsTab !== "eur"
              })}> <img src="/assets/flags/eur.svg" className='w-5 inline-block' alt="" /> <div className='md:pr-0'>EUR</div></button>
              <button onClick={() => changeWalletTab("gbp")} className={classNames('md:flex gap-2 items-center rounded-md md:rounded-full py-1 px-5 text-sm/6 font-semibold dark:text-white focus:outline-none dark:hover:bg-white/5 hover:bg-slate-200 focus:outline-3 focus:outline-purple-700', {
                'outline-none outline-3 outline-purple-700': walletsTab === "gbp",
                '': walletsTab !== "gbp"
              })}> <img src="/assets/flags/gbp.svg" className='w-5 inline-block' alt="" /> <div className='md:pr-0'>GBP</div></button>
            </div>
          </div>
        </div>


        <div className='mb-10 px-6'>
          <div className='flex justify-between'>
            <div className='flex items-center gap-3'>
              <button onClick={() => changeTab("external")} className={classNames('rounded-full py-1 px-5 text-sm/6 font-semibold dark:text-white focus:outline-none dark:hover:bg-white/5 hover:bg-slate-200 focus:outline-3 focus:outline-purple-700', {
                'outline-none outline-3 outline-purple-700': beneficiaryTab === "external",
                '': beneficiaryTab !== "external"
              })}>Bank</button>
              <button onClick={() => changeTab("internal")} className={classNames('rounded-full py-1 px-5 text-sm/6 font-semibold dark:text-white focus:outline-none dark:hover:bg-white/5 hover:bg-slate-200 focus:outline-3 focus:outline-purple-700', {
                'outline-none outline-3 outline-purple-700': beneficiaryTab === "internal",
                '': beneficiaryTab !== "internal"
              })}>Orion Pay</button>
            </div>

            <div>
              <Link to={'/u/beneficiaries/add'} className='flex gap-2 items-center font-bold'>
                <BiPlusCircle /> Add
              </Link>
            </div>
          </div>
        </div>

        <div className='px-7'>
          {beneficiaryTab === "external" ?
            <div className=''>

            <div className='mb-10 w-full md:w-[300px]'>
              <CustomInput
                type='text'
                name='remark'
                value={searchInput}
                onChange={(e) => setSearchInput(e)}
                placeholder='Enter Search Keyword'
                disabled={isFetching}
              />
            </div>

              <div className='px-0'>
                {!isFetching ?
                  filteredOptionsExternal.length > 0 ?
                    filteredOptionsExternal.map((b, i) => (
                      <div className='flex justify-between gap-3 items-center mb-3' key={i}>
                        <div className='flex gap-3 items-start'>
                          <div className='w-[50px] h-[50px] bg-neutral-200 dark:bg-neutral-800 rounded-xl flex justify-center items-center'>
                            <FaUser className="w-4 h-4" />
                          </div>
                          <div>
                            <div className='font-bold text-sm'>{b.name}</div>
                            <div className='text-[13px] text-neutral-700 dark:text-neutral-300 dark:text-opacity-85 text-opacity-65'>{b.bank}</div>
                            <div className='text-[13px] text-neutral-700 dark:text-neutral-300 dark:text-opacity-85 text-opacity-65'>{b.number}</div>
                          </div>
                        </div>
                        <div>
                          <button onClick={() => handleDelete(b.recipient_id)} className='text-right font-bold text-sm text-red-700 dark:text-red-500 text-opacity-85 dark:text-opacity-70'>
                            <span className='hidden md:inline-block'>Delete</span>
                            <PiTrashSimpleBold className='w-4 h-4 inline-block md:hidden' />
                          </button>
                        </div>
                      </div>
                    )) :
                    <div className='w-full h-[250px] flex justify-center px-5 items-center'>
                      <div className='text-slate-900 dark:text-neutral-200 text-opacity-70 dark:text-opacity-70 font-light'>
                        <center>
                          <div className='mb-5 text-lg'>No record</div>
                          <BsViewList className='text-[30px]' />
                        </center>
                      </div>
                    </div>
                  :
                  Array.from(Array(5)).map((_, i) => (
                    <div className='flex justify-between gap-3 items-center mb-3' key={i}>
                      <div className='flex gap-3 items-center'>
                        <Skeleton
                          startColor={resolvedTheme === "dark" ? `whiteAlpha.400` : `gray.300`}
                          endColor={resolvedTheme === "dark" ? `whiteAlpha.100` : `gray.100`}
                          w={'50px'} h={'50px'} borderRadius={'10%'} />
                        <div>
                          <Skeleton
                            startColor={resolvedTheme === "dark" ? `whiteAlpha.400` : `gray.300`}
                            endColor={resolvedTheme === "dark" ? `whiteAlpha.100` : `gray.100`}
                            w={'150px'} h={'14px'} mb={1} borderRadius={'10%'} />
                          <Skeleton
                            startColor={resolvedTheme === "dark" ? `whiteAlpha.400` : `gray.300`}
                            endColor={resolvedTheme === "dark" ? `whiteAlpha.100` : `gray.100`}
                            w={'60px'} h={'10px'} mb={1} borderRadius={'10%'} />
                          <Skeleton
                            startColor={resolvedTheme === "dark" ? `whiteAlpha.400` : `gray.300`}
                            endColor={resolvedTheme === "dark" ? `whiteAlpha.100` : `gray.100`}
                            w={'80px'} h={'10px'} mb={1} borderRadius={'10%'} />
                        </div>
                      </div>
                      <div>
                        <Skeleton
                          startColor={resolvedTheme === "dark" ? `whiteAlpha.400` : `gray.300`}
                          endColor={resolvedTheme === "dark" ? `whiteAlpha.100` : `gray.100`}
                          w={'40px'} h={'10px'} mb={1} borderRadius={'10%'} />
                      </div>
                    </div>
                  ))
                }
              </div>
            </div> :
            <div>
              <div className='px-0'>
                {!isFetching ?
                  filteredOptionsInternal.length > 0 ?
                    filteredOptionsInternal.map((b, i) => (
                      <div className='flex justify-between gap-3 items-center mb-3' key={i}>
                        <div className='flex gap-3 items-start'>
                          <div className='w-[50px] h-[50px] bg-neutral-200 dark:bg-neutral-800 rounded-xl flex justify-center items-center'>
                            <FaUser className="w-4 h-4" />
                          </div>
                          <div>
                            <div className='font-bold text-sm'>{b.name}</div>
                            <div className='text-[13px] text-neutral-700 dark:text-neutral-300 dark:text-opacity-85 text-opacity-65'>{b.bank}</div>
                          </div>
                        </div>
                        <div>
                          <button onClick={() => handleDelete(b.recipient_id)} className='text-right font-bold text-sm text-red-700 dark:text-red-500 text-opacity-85 dark:text-opacity-70'>
                            <span className='hidden md:inline-block'>Delete</span>
                            <PiTrashSimpleBold className='w-4 h-4 inline-block md:hidden' />
                          </button>
                        </div>
                      </div>
                    )) :
                    <div className='w-full h-[250px] flex justify-center px-5 items-center'>
                      <div className='text-slate-900 dark:text-neutral-200 text-opacity-70 dark:text-opacity-70 font-light'>
                        <center>
                          <div className='mb-5 text-lg'>No record</div>
                          <BsViewList className='text-[30px]' />
                        </center>
                      </div>
                    </div>
                  :
                  Array.from(Array(5)).map((_, i) => (
                    <div className='flex justify-between gap-3 items-center mb-3' key={i}>
                      <div className='flex gap-3 items-center'>
                        <Skeleton
                          startColor={resolvedTheme === "dark" ? `whiteAlpha.400` : `gray.300`}
                          endColor={resolvedTheme === "dark" ? `whiteAlpha.100` : `gray.100`}
                          w={'50px'} h={'50px'} borderRadius={'10%'} />
                        <div>
                          <Skeleton
                            startColor={resolvedTheme === "dark" ? `whiteAlpha.400` : `gray.300`}
                            endColor={resolvedTheme === "dark" ? `whiteAlpha.100` : `gray.100`}
                            w={'150px'} h={'14px'} mb={1} borderRadius={'10%'} />
                          <Skeleton
                            startColor={resolvedTheme === "dark" ? `whiteAlpha.400` : `gray.300`}
                            endColor={resolvedTheme === "dark" ? `whiteAlpha.100` : `gray.100`}
                            w={'60px'} h={'10px'} mb={1} borderRadius={'10%'} />
                          {/* <Skeleton
                            startColor={resolvedTheme === "dark" ? `whiteAlpha.400` : `gray.300`}
                            endColor={resolvedTheme === "dark" ? `whiteAlpha.100` : `gray.100`}
                            w={'80px'} h={'10px'} mb={1} borderRadius={'10%'} /> */}
                        </div>
                      </div>
                      <div>
                        <Skeleton
                          startColor={resolvedTheme === "dark" ? `whiteAlpha.400` : `gray.300`}
                          endColor={resolvedTheme === "dark" ? `whiteAlpha.100` : `gray.100`}
                          w={'40px'} h={'10px'} mb={1} borderRadius={'10%'} />
                      </div>
                    </div>
                  ))
                }
              </div>
            </div>
          }
        </div>

      </div>
      <div className='py-8'></div>

      <AlertDialog
        isOpen={isConfirmOpen}
        leastDestructiveRef={cancelRef}
        onClose={onConfirmClose}
        isCentered
        closeOnOverlayClick={false}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold' className='text-neutral-800 dark:text-white bg-neutral-100 dark:bg-neutral-900'>
              Delete Recipient
            </AlertDialogHeader>
            {/* <AlertDialogCloseButton className='text-neutral-800 dark:text-white' /> */}

            <AlertDialogBody className='text-neutral-800 dark:text-white bg-neutral-100 dark:bg-neutral-900'>
              Are you sure? This recipient will be removed from your beneficiary list.
            </AlertDialogBody>

            <AlertDialogFooter className='text-neutral-800 dark:text-white bg-neutral-100 dark:bg-neutral-900'>
              <Button ref={cancelRef} onClick={handleDeleteCancel} isDisabled={isDeleting}>
                Cancel
              </Button>
              <Button colorScheme='red' onClick={() => handleConfirmDelete()} ml={3} isDisabled={isDeleting}>
                {
                  !isDeleting ?
                    <span>Delete</span> :
                    <CgSpinner />
                }
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}

export default Beneficiaries