import CustomInput from '@/components/CustomInput';
import CustomSelect from '@/components/CustomSelect';
import { useApp } from '@/contexts/appContext';
import http from '@/lib/http';
import { walletConfig } from '@/utils/constants';
import { handleResponseError } from '@/utils/errors';
import { useToast } from '@chakra-ui/react';
import { MPWCheckoutModal, useMPWCheckout } from 'mpw-checkout-react';
import React, { useEffect, useMemo, useState } from 'react'
import { CgSpinner } from 'react-icons/cg';
import { IoArrowBackCircleOutline } from "react-icons/io5";
import { useNavigate, useSearchParams } from 'react-router-dom';

function PayFundWithCard() {

  const navigate = useNavigate();
  const toast = useToast();
  const { wallets, user } = useApp();
  const { payReference, isOpen, orderReferenceCode } = useMPWCheckout();

  useEffect(() => {
    const getBvnKycInfo = user!.kyc.details.find((e) => e.parameter === "bvn")!;
    if (getBvnKycInfo.status === "Pending") {
      toast({
        title: 'Error',
        description: 'Complete kyc to continue',
        status: 'warning',
        duration: 5000,
        isClosable: true,
      })
      navigate('/u/dashboard');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  const [searchParams] = useSearchParams();

  const urlWallet = useMemo(() => {
    const t = searchParams.get("wallet");
    if (!t) return "ngn".toUpperCase();
    if (wallets.map((w) => w.currency).includes(t)) {
      return t.toUpperCase();
    } else {
      return "ngn".toUpperCase()
    }
  }, [searchParams, wallets])

  const [fundWalletInput, setFundWalletInput] = useState({
    currency: urlWallet,
    wallet_id: wallets.find((w) => w.currency === urlWallet.toLowerCase())?.wallet_id || '',
    amount: ""
  });
  const [isFetching, setIsFetching] = useState(false);

  async function fundWallet() {
    setIsFetching(true);
    console.log("fundWalletInput", fundWalletInput);
    try {
      const resp = await http('wallet/fund/initiate', {
        wallet_id: fundWalletInput.wallet_id, amount: fundWalletInput.amount, method: [
          "ussd",
          "dynamic_virtual_account",
          "card"
        ]
      });
      console.log(resp.data);
      toast({
        title: 'Success',
        description: resp.message,
        status: 'success',
        duration: 5000,
        isClosable: true,
      })
      // window.open((resp.data! as any).payment_link, '_blank', 'noopener,noreferrer');
      payReference({
        orderReferenceCode: (resp.data! as any).reference_code,
        onSuccess: (data) => {
          console.log('Payment successful', data)
          toast({
            title: 'Success',
            description: 'Payment successful',
            status: 'success',
            duration: 15000,
            isClosable: true,
          });
          navigate(-1);
        },
        onFailure: (data) => {
          console.log('Payment failed', data)
          toast({
            title: 'Error',
            description: data.message,
            status: 'error',
            duration: 15000,
            isClosable: true,
          })
          setIsFetching(false);
        },
        onClose: (data) => {
          console.log("Closed data:", data)
          setIsFetching(false);
        }
      });
    } catch (error) {
      console.log(error);
      setIsFetching(false);
      handleResponseError(error, toast);
    }
  }

  const isAddExternalBeneficiaryFormValid = useMemo(() => {
    if (
      fundWalletInput.wallet_id.length > 0 &&
      fundWalletInput.amount.length > 0) {
      return true
    } else {
      return false;
    }
  }, [fundWalletInput]);

  function onWalletChange(e: string) {
    setFundWalletInput((i) => ({ ...i, currency: e, wallet_id: wallets.find((w) => w.currency === e.toLowerCase())?.wallet_id || '' }));
  }

  return (
    <>
      <div className='pt-8 md:pt-7'>

        <div className='px-6'>
          <div className='block md:flex w-full justify-center mt-0 md:mt-[100px]'>
            <div className='bg-none md:bg-white dark:bg-none dark:md:bg-neutral-800 px-0 py-0 md:px-[60px] md:py-[50px] w-full lg:w-[70%] rounded-xl'>

              <div className='text-black dark:text-neutral-100 '>

                <div className='mb-10 -ml-1'>
                  <div className='flex items-center gap-5'>
                    <button className='' onClick={() => navigate(-1)}>
                      <IoArrowBackCircleOutline className="w-8 h-8" />
                    </button>
                    <div className='font-extrabold text-xl'>Fund With Card</div>
                  </div>
                </div>
                <div className='block md:flex justify-center w-full'>
                  <div className=' w-full md:w-[300px] lg:w-[400px] px-0 md:px-5'>

                    <div className='mb-6'>

                      <div className='text-sm font-normal mb-1'> <span className='text-red-600'>*</span> Wallet</div>
                      <CustomSelect
                        value={fundWalletInput.currency}
                        options={wallets.map((w) => w.currency.toUpperCase())}
                        onChange={(e) => onWalletChange(e)}
                        placeholder='Select'
                        disabled={isFetching}
                      />
                    </div>

                    <div className='mb-10'>
                      <div className='text-sm font-normal mb-1'> <span className='text-red-600'>*</span> Amount</div>
                      <CustomInput
                        type='number'
                        name='amount'
                        value={fundWalletInput.amount}
                        onChange={(e) => setFundWalletInput((i) => ({ ...i, amount: e }))}
                        placeholder='0.00'
                        icon={walletConfig.find((e) => e.currency === fundWalletInput.currency.toLowerCase())?.icon}
                        disabled={isFetching}
                      />
                    </div>

                    <div className='mb-5'>
                      <button onClick={() => fundWallet()} disabled={isFetching || !isAddExternalBeneficiaryFormValid || isOpen} className='justify-center flex w-full text-sm text-neutral-100 font-bold bg-purple-800 shadow-md dark:bg-purple-700 px-5 py-4 rounded-lg disabled:bg-opacity-50 dark:disabled:disabled:bg-opacity-20'>
                        {
                          isFetching ?
                            <CgSpinner className='animate-spin' /> :
                            <span>Proceed</span>
                        }
                      </button>
                    </div>
                  </div>
                </div>

              </div>

            </div>
          </div>
        </div>

        <div className='py-8'></div>
      </div>
      <MPWCheckoutModal isOpen={isOpen} orderReferenceCode={orderReferenceCode} />
    </>
  )
}

export default PayFundWithCard