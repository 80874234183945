import React, { useEffect, useMemo, useState } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Hide,
  Show,
} from '@chakra-ui/react'
import {
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from '@chakra-ui/react'
import { IoArrowBackCircleOutline } from "react-icons/io5";
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import WalletSlider from '@/components/WalletSlider';
import { useToast } from '@chakra-ui/react';
import { useApp } from '@/contexts/appContext';
import { walletConfig, walletList } from '@/utils/constants';
import { handleResponseError } from '@/utils/errors';
import http from '@/lib/http';
import { CgSpinner } from 'react-icons/cg';
import CustomInput from '@/components/CustomInput';
import { currencyFormat } from '@/utils/helpers';
import CustomSelect from '@/components/CustomSelect';
import { IBeneficiary } from '@/types/global.types';

function PaySendWallet() {

  const navigate = useNavigate();
  const toast = useToast();
  const { user, wallets } = useApp();

  const [searchParams] = useSearchParams();
  const urlWallet = useMemo(() => {
    const w = searchParams.get('wallet');
    if (w) {
      if (walletList.includes(w)) {
        return w
      } else {
        return "ngn";
      }
    } else {
      return "ngn";
    }
  }, [searchParams]);

  const [activeWallet, setActiveWallet] = useState(urlWallet);
  const [isSending, setIsSending] = useState(false);
  const [tnxRef, setTnxRef] = useState("");
  const [beneficiaries, setBeneficiaries] = useState<IBeneficiary[]>([]);
  const [sendToWalletInput, setSendToWalletInput] = useState({
    "currency": activeWallet,
    "recipient_id": "",
    "amount": "",
    "remark": ""
  });
  const [isFetchingBeneficiaries, setIsFetchingBeneficiaries] = useState(true);
  const [walletPopupTab, setSendToWalletPopupTab] = useState<"confirm" | "success">("confirm");
  const { isOpen: isSendToWalletModalOpen, onOpen: onSendToWalletModalOpen, onClose: onSendToWalletModalClose } = useDisclosure()

  const isSendToWalletFormValid = useMemo(() => {
    if (sendToWalletInput.currency.length > 0 && sendToWalletInput.amount.length > 0 && parseFloat(sendToWalletInput.amount) > 0 && sendToWalletInput.recipient_id.length > 0) {
      return true;
    } else {
      return false;
    }
  }, [sendToWalletInput])


  async function getBeneficiaries() {
    try {
      const resp = await http(`wallet/transfer/internal/${activeWallet.toLowerCase()}/recipient/details`);
      resp.data ? setBeneficiaries(resp.data as any) : setBeneficiaries([]);
      setIsFetchingBeneficiaries(false)
    } catch (error) {
      console.log(error);
      setBeneficiaries([])
      setIsFetchingBeneficiaries(false)
      handleResponseError(error, toast);
    }
  }  

  useEffect(() => {
    setSendToWalletInput((i) => ({ ...i, wallet_id: "" }));
    getBeneficiaries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeWallet])

  function handleOnSendToWalletModalOpen() {
    const getBvnKycInfo = user!.kyc.details.find((e) => e.parameter === "bvn")!;
    if (getBvnKycInfo.status === "Pending") {
      toast({
        title: 'Error',
        description: 'Complete kyc to continue',
        status: 'warning',
        duration: 5000,
        isClosable: true,
      })
    } else {
      if (!wallets.map(e => e.currency.toLowerCase()).includes(activeWallet.toLowerCase())) {
        toast({
          title: 'Error',
          description: `Kindly create ${activeWallet.toUpperCase()} wallet`,
          status: 'warning',
          duration: 5000,
          isClosable: true,
          position: 'top'
        })
      } else {
        const w = wallets.find(e => e.currency.toLowerCase() === activeWallet.toLowerCase())!;
        if (parseFloat(w.available_balance) < parseFloat(sendToWalletInput.amount)) {
          toast({
            title: 'Error',
            description: `Insufficient balance`,
            status: 'error',
            duration: 5000,
            isClosable: true,
            position: 'top'
          })
        } else {
          setSendToWalletPopupTab("confirm")
          setIsSending(false)
          onSendToWalletModalOpen();
        }
      }
    }
  }

  function handleOnSendToWalletModalClose() {
    onSendToWalletModalClose();
    setIsSending(false);
    setSendToWalletPopupTab("confirm");
  }

  async function handleSendToWallet() {
    setIsSending(true);
    try {
      const resp = await http<any>(`wallet/transfer/internal/${sendToWalletInput.currency}/initiate/single`, { ...sendToWalletInput });
      console.log("Send Resp:", resp.data);
      setIsSending(false);
      resp.data && setTnxRef(resp.data.reference_code);
      setSendToWalletPopupTab("success");
    } catch (error) {
      console.log(error);
      setIsSending(false);
      handleResponseError(error, toast);

    }
  }

  function getActiveBeneficiaryInfo() {
    return beneficiaries.find((e) => e.recipient_id === sendToWalletInput.recipient_id)
  }

  return (
    <>
      <div className='pt-8 md:pt-7'>

        <div className='px-6'>
          <div className='block md:flex w-full justify-center mt-0 md:mt-[10%]'>
            <div className='bg-none md:bg-white dark:bg-none dark:md:bg-neutral-800 px-2 py-2 md:px-[60px] md:py-[50px] w-full lg:w-[70%] rounded-xl'>

              <div className='text-black dark:text-neutral-100 '>

                <div className='mb-10'>
                  <div className='flex items-center gap-5'>
                    <button className='' onClick={() => navigate(-1)}>
                      <IoArrowBackCircleOutline className="w-8 h-8" />
                    </button>
                    <div className='font-extrabold text-xl'>Send To Wallet</div>
                  </div>
                </div>

                <div className='block md:flex justify-center w-full'>
                  <div className=' w-full md:w-[300px] lg:w-[400px] px-0 md:px-5'>

                    <div className='w-full mb-[28px]'>
                      <WalletSlider size='full' onSliderChange={setActiveWallet} />
                    </div>

                    <div className='mb-6'>
                      <div className='text-sm font-normal mb-1 flex gap-1 items-center'>
                        <span className='text-red-600'>*</span>
                        <div className='flex justify-between flex-1 items-center'>
                          <div className='flex items-center gap-2'>
                            <span>Recipient</span>
                            {isFetchingBeneficiaries && <CgSpinner className='animate-spin' />}
                          </div>
                          <div>
                            <Link to="/u/beneficiaries/add" className='text-xs text-purple-600 dark:text-purple-500'>Add beneficiary</Link>
                          </div>
                        </div>
                      </div>
                      <CustomSelect
                        value={sendToWalletInput.recipient_id}
                        options={beneficiaries}
                        onChange={(e) => setSendToWalletInput((i) => ({ ...i, recipient_id: e }))}
                        placeholder='Select'
                        display={{
                          title: "name",
                          body: "bank",
                          value: "recipient_id"
                        }}
                        disabled={isSending || isFetchingBeneficiaries}
                      />
                    </div>

                    <div className='mb-5'>
                      <div className='text-sm font-normal mb-1'> <span className='text-red-600'>*</span> Amount</div>
                      <CustomInput
                        type='number'
                        name='amount'
                        value={sendToWalletInput.amount}
                        onChange={(e) => setSendToWalletInput((i) => ({ ...i, amount: e }))}
                        placeholder='0.00'
                        icon={walletConfig.find((e) => e.currency.toLowerCase() === activeWallet.toLowerCase())?.icon}
                        disabled={isSending}
                      />
                    </div>

                    <div className='mb-10'>
                      <div className='text-sm font-normal mb-1'> <span className='text-red-600'>*</span> Remark</div>
                      <CustomInput
                        type='text'
                        name='remark'
                        value={sendToWalletInput.remark}
                        onChange={(e) => setSendToWalletInput((i) => ({ ...i, remark: e }))}
                        placeholder='Remark'
                        disabled={isSending}
                      />
                    </div>

                    <div className='mb-5'>
                      <button onClick={() => handleOnSendToWalletModalOpen()} disabled={isSending || !isSendToWalletFormValid} className='justify-center flex w-full text-sm text-neutral-100 font-bold bg-purple-800 shadow-md dark:bg-purple-700 px-5 py-4 rounded-lg disabled:bg-opacity-50 dark:disabled:disabled:bg-opacity-20'>
                        {
                          isSending ?
                            <CgSpinner className='animate-spin' /> :
                            <span>Send</span>
                        }
                      </button>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

        <div className='py-8'></div>
      </div>


      <Hide below='md'>
        <Modal isOpen={isSendToWalletModalOpen} onClose={handleOnSendToWalletModalClose} closeOnOverlayClick={false}>
          <ModalOverlay />
          <ModalContent>
            {/* <ModalHeader>Title</ModalHeader> */}
            {walletPopupTab !== "success" && <ModalCloseButton className='text-neutral-800 dark:text-white' />}
            <ModalBody className='text-neutral-800 dark:text-white bg-neutral-100 dark:bg-neutral-900'>
              {walletPopupTab === "success" ?
                <div className='pt-8 pb-[100px]'>
                  <div className='flex mb-2 items-center gap-3'>
                    <div className='font-extrabold text-2xl'>Transfer successful</div>
                    {/* <img src={`/assets/flags/${walletConfig.find((e) => e.currency.toLowerCase() === activeWallet.toLowerCase())?.currency}.svg`} className='w-[25px] h-[25px]' alt="" /> */}
                  </div>
                  <div className='mb-10'>
                    You have successfully transfered &nbsp;
                    {currencyFormat(sendToWalletInput.amount, walletConfig.find((e) => e.currency.toLowerCase() === activeWallet.toLowerCase())?.icon)}
                    &nbsp;to Orion pay user ({getActiveBeneficiaryInfo()?.name})

                  </div>
                  <div className='w-full flex justify-center mb-10'>
                    <img src="/assets/shield-check.png" className='w-[70px]' alt="" />
                  </div>
                  <div className='mb-10'>
                    <center>
                      <div className='text-xs'>Reference code:</div>
                      <div className='text-purple-700 dark:text-purple-400 text-xs'>{tnxRef}</div>
                    </center>
                  </div>
                  <div className='flex justify-center'>
                    <button className='text-purple-700 dark:text-purple-400 font-bold' onClick={() => navigate(`/u/transactions?wallet=${activeWallet}`)}>Continue</button>
                  </div>
                </div> :
                <div className='py-8'>
                  <div className='flex mb-2 items-center gap-3'>
                    <div className='font-extrabold text-2xl'>Confirm Transfer</div>
                    {/* <img src={`/assets/flags/${walletConfig.find((e) => e.currency.toLowerCase() === activeWallet.toLowerCase())?.currency}.svg`} className='w-[25px] h-[25px]' alt="" /> */}
                  </div>
                  <div className='mb-5'>Kindly confirm the transaction you are about to make on your {walletConfig.find((e) => e.currency.toLowerCase() === activeWallet.toLowerCase())?.currency.toUpperCase()} ({walletConfig.find((e) => e.currency.toLowerCase() === activeWallet.toLowerCase())?.icon}) wallet</div>
                  <div className='w-full mb-[60px]'>
                    <div className='mb-5'>
                      <div className='text-sm'>To:</div>
                      <div className=''>
                        <div className='font-bold'>{getActiveBeneficiaryInfo()?.name}</div>
                      </div>
                    </div>
                    <div className='mb-5'>
                      <div className='text-sm'>Amount:</div>
                      <div className='text-2xl font-bold'>{currencyFormat(sendToWalletInput.amount, walletConfig.find((e) => e.currency.toLowerCase() === activeWallet.toLowerCase())?.icon)}</div>
                    </div>
                  </div>
                  <center>
                    <button className='py-2 px-5 flex gap-3 items-center bg-purple-800 rounded-xl text-neutral-200 font-bold disabled:bg-opacity-50' onClick={() => handleSendToWallet()} disabled={isSending}>
                      {!isSending ? <div>Confirm Transaction</div> : <div>Creating</div>}
                      {isSending && <div className='animate-spin'><CgSpinner /></div>}
                    </button>
                  </center>
                  <div className='py-[22px]'></div>
                </div>}
            </ModalBody>
          </ModalContent>
        </Modal>
      </Hide>

      <Show below='md'>
        <Drawer placement={"bottom"} isOpen={isSendToWalletModalOpen} onClose={handleOnSendToWalletModalClose} closeOnOverlayClick={false}>
          <DrawerOverlay />
          <DrawerContent>
            {walletPopupTab !== "success" && <DrawerCloseButton className='text-neutral-800 dark:text-white' />}
            <DrawerBody className='text-neutral-800 dark:text-white bg-neutral-100 dark:bg-neutral-900'>
              {walletPopupTab === "success" ?
                <div className='pt-8 pb-[100px]'>
                  <div className='flex mb-2 items-center gap-3'>
                    <div className='font-extrabold text-2xl'>Transfer successful</div>
                    {/* <img src={`/assets/flags/${walletConfig.find((e) => e.currency.toLowerCase() === activeWallet.toLowerCase())?.currency}.svg`} className='w-[25px] h-[25px]' alt="" /> */}
                  </div>
                  <div className='mb-10'>
                    You have successfully transfered &nbsp;
                    {currencyFormat(sendToWalletInput.amount, walletConfig.find((e) => e.currency.toLowerCase() === activeWallet.toLowerCase())?.icon)}
                    &nbsp;to Orion pay user ({getActiveBeneficiaryInfo()?.name})

                  </div>
                  <div className='w-full flex justify-center mb-10'>
                    <img src="/assets/shield-check.png" className='w-[70px]' alt="" />
                  </div>
                  <div className='mb-10'>
                    <center>
                      <div className='text-xs'>Reference code:</div>
                      <div className='text-purple-700 dark:text-purple-400 text-xs'>{tnxRef}</div>
                    </center>
                  </div>
                  <div className='flex justify-center'>
                    <button className='text-purple-700 dark:text-purple-400 font-bold' onClick={() => navigate(`/u/transactions?wallet=${activeWallet}`)}>Continue</button>
                  </div>
                </div> :
                <div className='py-8'>
                  <div className='flex mb-2 items-center gap-3'>
                    <div className='font-extrabold text-2xl'>Confirm Transfer</div>
                    {/* <img src={`/assets/flags/${walletConfig.find((e) => e.currency.toLowerCase() === activeWallet.toLowerCase())?.currency}.svg`} className='w-[25px] h-[25px]' alt="" /> */}
                  </div>
                  <div className='mb-5'>Kindly confirm the transaction you are about to make on your {walletConfig.find((e) => e.currency.toLowerCase() === activeWallet.toLowerCase())?.currency.toUpperCase()} ({walletConfig.find((e) => e.currency.toLowerCase() === activeWallet.toLowerCase())?.icon}) wallet</div>
                  <div className='w-full mb-[60px]'>
                    <div className='mb-5'>
                      <div className='text-sm'>To:</div>
                      <div className=''>
                        <div className='font-bold'>{getActiveBeneficiaryInfo()?.name}</div>
                      </div>
                    </div>
                    <div className='mb-5'>
                      <div className='text-sm'>Amount:</div>
                      <div className='text-2xl font-bold'>{currencyFormat(sendToWalletInput.amount, walletConfig.find((e) => e.currency.toLowerCase() === activeWallet.toLowerCase())?.icon)}</div>
                    </div>
                  </div>
                  <center>
                    <button className='py-2 px-5 flex gap-3 items-center bg-purple-800 rounded-xl text-neutral-200 font-bold disabled:bg-opacity-50' onClick={() => handleSendToWallet()} disabled={isSending}>
                      {!isSending ? <div>Confirm Transaction</div> : <div>Creating</div>}
                      {isSending && <div className='animate-spin'><CgSpinner /></div>}
                    </button>
                  </center>
                  <div className='py-[22px]'></div>
                </div>}
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </Show>
    </>
  )
}

export default PaySendWallet