// import LiveChat from '@/components/Livechat'
import React, { useEffect } from 'react'

function Support() {

  useEffect(() => {
    // Load and initialize Tawk_API here
    //@ts-ignore
    const Tawk_API = window?.Tawk_API || {};
    Tawk_API.onLoad = () => {
      // Code to run when Tawk_API is loaded
      // console.log("Tawk_API loaded");
    };

    // Replace 'your-property-id' with your actual Tawk.to property ID
    // const Tawk_LoadStart = new Date();
    const s1 = document.createElement("script");
    const s0 = document.getElementsByTagName("script")[0];
    s1.async = true;
    s1.src = 'https://embed.tawk.to/66659d339a809f19fb3bc2d2/1hvues4tk';
    s1.charset = "UTF-8";
    s1.setAttribute("crossorigin", "*");
    s0.parentNode?.insertBefore(s1, s0);

    // Cleanup function
    return () => {
      // Cleanup or perform actions when the component is unmounted
      // For example, you might want to hide the Tawk.to widget
      //@ts-ignore
      if (window?.Tawk_API) {
        //@ts-ignore
        window?.Tawk_API.hideWidget();
      }
    };
  }, []);

  function toggleChatWidget() {
    if (window !== undefined && window !== null) {
      //@ts-ignore`
      window?.Tawk_API.toggle();
    }
  }

  return (
    <>
      <div className='pt-8 md:pt-7'>
        <div className='mb-10 px-6'>
          <div className='font-extrabold text-xl'>Support</div>
        </div>

        <div className='px-6'>
          <div className='font-extrabold mb-3 '>Tell us how we can help 👋</div>
          <div className='mb-10'>Our super trained and compentent team <br /> are
            standing by for services and support.</div>

          <button className='px-6 py-2 text-sm bg-purple-700 rounded-xl text-white' onClick={() => toggleChatWidget()}>Start chat</button>

          {/* <LiveChat /> */}
        </div>
      </div>
    </>
  )
}

export default Support