
import React, { useEffect, useState } from 'react'
import { FaSpinner } from 'react-icons/fa6';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useApp } from '@/contexts/appContext';
import CustomInput from '@/components/CustomInput';
import http from '@/lib/http';
// import CustomFileInput from '@/components/CustomFileInput';
import { handleResponseError } from '@/utils/errors';
import { useToast } from '@chakra-ui/react';
// import { FaCheckCircle } from 'react-icons/fa';
// import { CgSpinner } from "react-icons/cg";

function OnboardVerifyBvn() {

    const [searchParams, setSearchParams] = useSearchParams();
    const step = searchParams.get('step') ? ["1", "2", "3", "4"].includes(searchParams.get('step')!) ? searchParams.get('step')! : "1" : "1";

    const [tab, setTab] = useState<"1" | "2" | "3" | "4">(step as any);
    const [otp, setOtp] = useState("");
    const [verifyBvnInput, setVerifyBvnInput] = useState({
        number: "",
        phone: "",
        photo_url: ""
    });
    // const [selfieImage, setSelfieImage] = useState<File | null>(null);
    // const [isUploadingFile, setIsUploadingFile] = useState(false);

    const [isFetching, setIsFetching] = useState(false);
    const [isFetchingOtp, setIsFetchingOtp] = useState(false);

    const { setShowOnboard, user, setUser } = useApp();
    const toast = useToast();

    const navigate = useNavigate();

    function continueClicked() {
        setSearchParams({ step: "2" });
    }

    async function submitBvnKyc() {
        setIsFetching(true);
        console.log(verifyBvnInput)
        try {
            await http("user/verification/process/bvn", { ...verifyBvnInput, phone: `+234${verifyBvnInput.phone}` });
            const newKycDetails = [...user!.kyc.details];
            const index = newKycDetails.findIndex((k) => k.parameter === "bvn");
            if (index !== -1) {
                newKycDetails[index].status = "Successful"
                setUser((u) => ({ ...u!, kyc: { ...u!.kyc, details: newKycDetails } }));
            }
            const verifyOtpResp = await http("user/verification/process/bvn-phone-number", { mode: "send_otp" });
            console.log("verifyOtpResp:", verifyOtpResp)
            setSearchParams({ step: "3" });
            setIsFetching(false);
        } catch (error) {
            console.log(error);
            setIsFetching(false);
            handleResponseError(error, toast);
        }
    }

    async function verifyBvnKyc() {
        setIsFetching(true);
        try {
            await http("user/verification/process/bvn-phone-number", { mode: "verify_otp", otp: otp });
            const newKycDetails = [...user!.kyc.details];
            const index = newKycDetails.findIndex((k) => k.parameter === "bvn_phone_number");
            if (index !== -1) {
                newKycDetails[index].status = "Successful"
                setUser((u) => ({ ...u!, kyc: { ...u!.kyc, details: newKycDetails } }));
            }
            setSearchParams({ step: "4" });
            setIsFetching(false);
        } catch (error) {
            console.log(error);
            setIsFetching(false);
            handleResponseError(error, toast);
        }
    }

    function completeClicked() {
        window.location.href = "/u/dashboard";
        // setShowOnboard(null);
        // navigate('/u/dashboard');
    }

    function skipBvnOnboard() {
        setShowOnboard(null);
        navigate("/u/dashboard");
    }

    useEffect(() => {
        const newStep = searchParams.get('step');
        if (newStep && ["1", "2", "3", "4"].includes(newStep)) {
            const getBvnKycInfo = user!.kyc.details.find((e) => e.parameter === "bvn")!;
            if (getBvnKycInfo.status === "Pending") {
                setTab(newStep as any);
            } else {
                if (newStep === "1" || newStep === "2") {
                    setTab("3");
                } else {
                    setTab(newStep as any);
                }
            }
        } else {
            setTab("1");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParams]);

    // async function handleOnImageSelected(file: File | null) {
    //     setSelfieImage(file);
    //     if (!file) return;
    //     setVerifyBvnInput((v) => ({ ...v, photo_url: "" }));
    //     setIsUploadingFile(true);
    //     try {
    //         const formData = new FormData();
    //         formData.append('file', file);
    //         const uploadResp = await http<string>("misc/file-upload", formData, process.env.REACT_APP_PUBLIC_KEY);
    //         console.log("upload response", uploadResp.data);
    //         setVerifyBvnInput((v) => ({ ...v, photo_url: uploadResp.data! }));
    //         setIsUploadingFile(false);
    //     } catch (error) {
    //         console.log(error);
    //         setIsUploadingFile(false);
    //         handleResponseError(error, toast);
    //     }
    // }

    async function resendOtp() {
        setIsFetchingOtp(true);
        try {
            const verifyResp = await http("user/verification/process/bvn-phone-number", { mode: "send_otp" });
            console.log("phone verify response:", verifyResp);
            // const verifyMsg = verifyResp.message.split(" ");
            // setOtp(verifyMsg[verifyMsg.length - 1]);
            toast({
                title: 'Success',
                description: verifyResp.message,
                status: 'success',
                duration: 5000,
                isClosable: true,
            })
            setIsFetchingOtp(false)
        } catch (error) {
            console.log(error);
            setIsFetchingOtp(false);
            handleResponseError(error, toast);
        }
    }

    return (
        <>
            <div>
                <div className='overflow-auto top-0 left-0 pt-[100px] flex justify-center items-center w-full z-50 bg-[rgb(245,245,245)] dark:bg-neutral-900 min-h-lvh dark:text-neutral-100'>
                    <div className='w-full md:w-[500px]'>
                        {/* {tab !== "4" && <div className='text-right px-8 mb-2'>
                            <button className='text-purple-800 dark:text-purple-400 text-sm' onClick={skipBvnOnboard}>Skip</button>
                        </div>} */}
                        {tab === "1" ?
                            <div className='mt-[-100px]'>
                                <div className='text-right px-8 mb-2'>
                                    <button className='text-purple-800 dark:text-purple-400 text-sm' onClick={skipBvnOnboard}>Skip</button>
                                </div>
                                <center>
                                    <img src="/assets/login-img.svg" className='w-[80%] sm:w-[60%] md:w-[400px] md:h-[400px] lg:w-[400px] lg:h-[400px] aspect-square' alt="" />
                                </center>
                                <div className='flex justify-center flex-1'>
                                    <div className='px-10'>
                                        <center>
                                            <div className='font-extrabold text-2xl mb-3'>Let's Continue!</div>
                                            <div className='mb-5'>Complete kyc to unlock wallet and other features</div>
                                            <button className='text-purple-800 dark:text-purple-400 underline' onClick={() => continueClicked()}>Continue</button>
                                        </center>
                                    </div>
                                </div>
                            </div> :
                            tab === "2" ?
                                <div>
                                    <div className='text-right px-8 mb-2'>
                                        <button className='text-purple-800 dark:text-purple-400 text-sm' onClick={skipBvnOnboard}>Skip</button>
                                    </div>
                                    <div className='mb-8 pt-[30px] px-6'>

                                        <div className='mb-8'>
                                            <div className='mb-1 font-extrabold text-xl'>Submit BVN</div>
                                            <div>Kindly enter your 11 digits BVN to proceed</div>
                                        </div>

                                        <div className='mb-6'>
                                            <div className='text-sm font-normal mb-1'> <span className='text-red-600'>*</span> BVN</div>
                                            <CustomInput
                                                type='number'
                                                name='bvn'
                                                value={verifyBvnInput.number}
                                                onChange={(e) => setVerifyBvnInput((i) => ({ ...i, number: e }))}
                                                placeholder='BVN'
                                                disabled={isFetching}
                                            />
                                        </div>

                                        <div className='mb-6'>
                                            <div className='text-sm font-normal mb-1'> <span className='text-red-600'>*</span> BVN Phone Number</div>
                                            <CustomInput
                                                type='number'
                                                name='phone'
                                                value={verifyBvnInput.phone}
                                                onChange={(e) => setVerifyBvnInput((i) => ({ ...i, phone: e }))}
                                                placeholder='8000000000'
                                                icon={'+234'}
                                                disabled={isFetching}
                                            />
                                            <div className='text-sm text-slate-600 dark:text-neutral-500 px-1 mt-2'>
                                                A one time password will be sent to the phone number
                                                registered to your BVN
                                            </div>
                                        </div>

                                        {/* <div className='mb-10'>
                                            <div className='text-sm font-normal mb-1 flex gap-1 items-center'>
                                                <span className='text-red-600'>*</span>
                                                <div className='flex items-center gap-2'>
                                                    <span>Upload Selfie</span>
                                                    {isUploadingFile && <CgSpinner className='animate-spin' />}
                                                    {selfieImage !== null && verifyBvnInput.photo_url && <FaCheckCircle className='text-green-700 dark:text-green-600' />}
                                                </div>
                                            </div>
                                            <CustomFileInput
                                                onChange={(f) => handleOnImageSelected(f)}
                                                disabled={isUploadingFile || isFetching}
                                            />
                                        </div> */}

                                        <div className='mb-5'>
                                            <button onClick={() => submitBvnKyc()} disabled={isFetching} className='justify-center flex w-full text-sm text-neutral-100 font-bold bg-purple-800 shadow-md dark:bg-purple-700 px-5 py-4 rounded-lg disabled:bg-opacity-50 dark:disabled:disabled:bg-opacity-20'>
                                                {
                                                    isFetching ?
                                                        <FaSpinner className='animate-spin' /> :
                                                        <span>Submit</span>
                                                }
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                :
                                tab === "3" ?
                                    <div className='mt-[-100px]'>
                                        <div className='text-right px-8 mb-2'>
                                            <button className='text-purple-800 dark:text-purple-400 text-sm' onClick={skipBvnOnboard}>Skip</button>
                                        </div>
                                        <div className='mb-8 pt-[30px] md:pb-[200px] px-6'>

                                            <div className='mb-8'>
                                                <div className='mb-1 font-extrabold text-xl'>Verify BVN</div>
                                                <div>An OTP has been sent to your bvn phone number</div>
                                            </div>

                                            <div className='mb-5'>
                                                <div className='text-sm font-normal mb-[5px]'> <span className='text-red-600'>*</span> OTP</div>
                                                <CustomInput
                                                    type='number'
                                                    name='otp'
                                                    value={otp}
                                                    onChange={(e) => setOtp(e)}
                                                    placeholder='OTP'
                                                    disabled={isFetching}
                                                />

                                                <div className='inline-block pt-2'>
                                                    <div className='mb-5 flex gap-1 items-center text-xs'>
                                                        <div>Didn't get a code ? </div>
                                                        <button className='text-purple-600 dark:text-purple-400 text-sm px-1' onClick={() => resendOtp()} disabled={isFetchingOtp}>
                                                            {
                                                                isFetchingOtp ?
                                                                    <FaSpinner className='animate-spin' /> :
                                                                    <span>resend</span>
                                                            }
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='mb-5'>
                                                <button onClick={() => verifyBvnKyc()} disabled={isFetching} className='justify-center flex w-full text-sm text-neutral-100 font-bold bg-purple-800 shadow-md dark:bg-purple-700 px-5 py-4 rounded-lg disabled:bg-opacity-50 dark:disabled:disabled:bg-opacity-20'>
                                                    {
                                                        isFetching ?
                                                            <FaSpinner className='animate-spin' /> :
                                                            <span>Verify</span>
                                                    }
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div className='pt-1'>

                                        <center>
                                            <div className='mb-3'>
                                                <img src="/assets/success-img2.svg" className='w-[300px] h-[300px]' alt="" />
                                            </div>

                                            <div className='mb-8'>
                                                <div className='mb-3 font-extrabold text-xl'>You're Verified</div>
                                                <div className='text-sm px-5'>You have successfully completed your Tier1 KYC process,
                                                    now you can start sending and receiving funds globally in minutes</div>
                                            </div>

                                            <div className='mb-5'>
                                                <div><button className='text-purple-600 dark:text-purple-400' onClick={() => completeClicked()}>Continue to dashboard</button></div>
                                            </div>
                                        </center>
                                    </div>
                        }

                        <div className='py-10'></div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default OnboardVerifyBvn