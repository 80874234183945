import React from 'react';
import { Routes, Route } from 'react-router-dom'
import Landing from './pages/Landing';
import Register from './pages/auth/Register';
import Login from './pages/auth/Login';
import Dashboard from './pages/user/Dashboard';
import UserLayout from './components/layouts/UserLayout';
import Pay from './pages/user/Pay';
import Transactions from './pages/user/Transactions';
import KYC from './pages/user/KYC';
import Profile from './pages/user/Profile';
import Security from './pages/user/Security';
import Support from './pages/user/Support';
import PaySend from './pages/user/PaySend';
import Wallet from './pages/user/Wallet';
import Beneficiaries from './pages/user/Beneficiaries';
import PaySendWallet from './pages/user/PaySendWallet';
import PaySendBank from './pages/user/PaySendBank';
import PayReceive from './pages/user/PayReceive';
import AuthLayout from './components/layouts/AuthLayout';
import OnboardVerifyPhone from './pages/user/onboard/OnboardVerifyPhone';
import OnboardVerifyBvn from './pages/user/onboard/OnboardVerifyBvn';
import NotFound from './pages/NotFound';
import OnboardLayout from './components/layouts/OnboardLayout';
import AddBeneficiaries from './pages/user/AddBeneficiaries';
import FundWallet from './pages/user/PayFundWithCard';
import OnboardVerifyNIN from './pages/user/onboard/OnboardVerifyNIN';
import OnboardVerifyAddress from './pages/user/onboard/OnboardVerifyAddress';
import OnboardVerifyEmail from './pages/user/onboard/OnboardVerifyEmail';
import PayFundBankTransfer from './pages/user/PayFundWithBankTransfer';
import TestPage from './pages/user/Test';

function App() {
  return (
    <Routes>
      {/* <Route path='' element={<Navigate to={'/u/dashboard'} />} ></Route> */}
      <Route path='' element={<Landing />} ></Route>
      <Route path='auth' element={<AuthLayout />}>
        <Route path='login' element={<Login />} ></Route>
        <Route path='signup' element={<Register />} ></Route>
      </Route>

      <Route element={<OnboardLayout />}>
        <Route path='/onboarding/verify-phone' element={<OnboardVerifyPhone />} ></Route>
        <Route path='/onboarding/verify-email' element={<OnboardVerifyEmail />} ></Route>
        <Route path='/onboarding/verify-bvn' element={<OnboardVerifyBvn />} ></Route>
        <Route path='/onboarding/verify-nin' element={<OnboardVerifyNIN />} ></Route>
        <Route path='/onboarding/verify-residential-address' element={<OnboardVerifyAddress />} ></Route>
      </Route>

      <Route element={<UserLayout />}>
        <Route path='/u/dashboard' element={<Dashboard />} ></Route>

        <Route path='/u/pay' element={<Pay />} ></Route>
        <Route path='/u/pay/receive' element={<PayReceive />} ></Route>
        <Route path='/u/pay/send' element={<PaySend />} ></Route>
        <Route path='/u/pay/send/wallet' element={<PaySendWallet />} ></Route>
        <Route path='/u/pay/send/bank' element={<PaySendBank />} ></Route>
        <Route path='/u/pay/fund/bank-transfer' element={<PayFundBankTransfer />} ></Route>
        <Route path='/u/pay/fund/card' element={<FundWallet />} ></Route>

        <Route path='/u/wallet' element={<Wallet />} ></Route>

        <Route path='/u/transactions' element={<Transactions />} ></Route>

        <Route path='/u/beneficiaries' element={<Beneficiaries />} ></Route>
        <Route path='/u/beneficiaries/add' element={<AddBeneficiaries />} ></Route>

        <Route path='/u/account/kyc' element={<KYC />} ></Route>
        <Route path='/u/account' element={<Profile />} ></Route>
        <Route path='/u/account/security' element={<Security />} ></Route>
        
        <Route path='/u/support' element={<Support />} ></Route>

        <Route path='/u/test' element={<TestPage />} ></Route>
      </Route>
      <Route path='*' element={<NotFound />}></Route>
    </Routes>
  );
}

export default App;
