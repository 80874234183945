
import React, { useEffect } from 'react'
import classNames from 'classnames';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
// import AppLayoutClient from '@/components/AppLayoutClient';
// import PanelHeader from '@/components/PanelHeader';
import { FaDoorOpen } from "react-icons/fa6";
import { GrCreditCard, GrTransaction } from "react-icons/gr";
import { FaQuestionCircle, FaUserEdit } from "react-icons/fa";
import { Image } from '@chakra-ui/react';
import ThemeButton from '../ThemeButton';
import { HiShieldCheck, HiUsers } from 'react-icons/hi';
import { AiTwotoneProfile } from 'react-icons/ai';
import { BiHomeAlt2 } from "react-icons/bi";
import MobileFooterTab from '../MobileFooterTab';
import { IoWalletOutline } from 'react-icons/io5';
import { useApp } from '@/contexts/appContext';
import SplashLoader from '../SplashLoader';
import { BsExclamationCircle } from 'react-icons/bs';

function UserLayout() {

    const { pathname } = useLocation();
    const navigate = useNavigate();
    const { logout, user, onlineStatus } = useApp();
    const { isAuthenticating, isAuthenticated, showOnboard } = useApp();

    function getPath(path?: number) {
        const split_path = pathname.split('/');
        return split_path[path ? path : split_path.length - 1];
    }

    useEffect(() => {
        if (!isAuthenticating && !isAuthenticated) {
            localStorage.removeItem('op-app-tkn');
            navigate('/auth/login', { replace: true });
        };
        if (showOnboard) {
            if (showOnboard === "phone" && getPath(1) !== "onboarding" && getPath(2) !== "verify-phone") {
                navigate('/onboarding/verify-phone', { replace: true });
            }
            if (showOnboard === "email" && getPath(1) !== "onboarding" && getPath(2) !== "verify-email") {
                navigate('/onboarding/verify-email', { replace: true });
            }
            if (showOnboard === "bvn" && getPath(1) !== "onboarding" && getPath(2) !== "verify-bvn") {
                navigate('/onboarding/verify-bvn', { replace: true });
            }
        }
        console.log("showOnboard", showOnboard)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticating, isAuthenticated, showOnboard]);

    return (
        <>
            {
                onlineStatus === "offline" &&
                <div className='w-full px-5 py-2 flex justify-between items-center gap-5 bg-red-900 relative z-[9999] text-neutral-100'>
                    <div className='flex items-center gap-2'>
                        <BsExclamationCircle />
                        <div className='text-xs'>You are offline. kindly check internet connection</div>
                    </div>
                    {/* <Link to={'/onboarding/verify-bvn'} className='text-xs px-2 py-1 rounded-xl bg-orange-950'>Complete</Link> */}
                </div>
            }
            {(!isAuthenticating && isAuthenticated)
                &&
                (
                    (showOnboard === "phone" && getPath(1) === "onboarding" && getPath(2) === "verify-phone")
                    || (showOnboard === "email" && getPath(1) === "onboarding" && getPath(2) === "verify-email")
                    || (showOnboard === "bvn" && getPath(1) === "onboarding" && getPath(2) === "verify-bvn") 
                    || showOnboard === null) ?
                <>
                    <MobileFooterTab />
                    <div className='bg-[rgb(245,245,245)] dark:bg-neutral-900 min-h-lvh dark:text-neutral-100 font-container'>
                        <div className='grid md:flex gap-0 min-h-lvh w-full '>
                            <div className='md:w-[250px] lg:w-[320px] w-full bg-[rgb(253,253,253)] dark:bg-[rgb(22,22,22)] hidden md:block border-r-2 border-r-[rgb(251,251,251)] dark:border-r-[rgb(24,24,24)]'>

                                <div className='p-10 flex justify-between items-center'>
                                    <Image src={'/logo.png'} className='w-[46px]' alt='logo' />
                                    <div>
                                        <ThemeButton />
                                    </div>
                                </div>

                                <div className=' hidden md:block md:h-auto md:pt-6 w-full pb-[100px]'>

                                    <Link to={`/u/dashboard`} className={classNames('bg-[rgb(253,253,253)] dark:bg-[rgb(22,22,22)] px-10 py-3 text-[14px] font-bold opacity-100 w-full block', {
                                        'text-purple-950 dark:text-purple-400 dark:text-opacity-90': getPath(2) === "dashboard",
                                        'text-neutral-700 dark:text-neutral-300 text': getPath(2) !== "dashboard",
                                    })}>
                                        <div className='flex items-center gap-4'>
                                            <div className={classNames('w-8 h-8 rounded-full text-[18px] flex justify-center items-center shadow-sm', {
                                                'bg-yellow-600 dark:bg-yellow-700': getPath(2) === "dashboard",
                                                '': getPath(2) !== "dashboard",
                                            })}>
                                                <BiHomeAlt2 className={classNames({
                                                    'text-neutral-100 dark:text-neutral-100': getPath(2) === "dashboard",
                                                    'text-neutral-900 dark:text-neutral-100': getPath(2) !== "dashboard",
                                                })} />
                                            </div>
                                            <div>Home</div>
                                        </div>
                                    </Link>

                                    <Link to={`/u/pay`} className={classNames('bg-[rgb(253,253,253)] dark:bg-[rgb(22,22,22)] px-10 py-3 text-[14px] font-bold opacity-100 w-full block', {
                                        'text-purple-950 dark:text-purple-400 dark:text-opacity-90': getPath(2) === "pay",
                                        'text-neutral-700 dark:text-neutral-300 text': getPath(2) !== "pay",
                                    })}>
                                        <div className='flex items-center gap-4'>
                                            <div className={classNames('w-8 h-8 rounded-full text-[18px] flex justify-center items-center shadow-sm', {
                                                'bg-yellow-600 dark:bg-yellow-700': getPath(2) === "pay",
                                                '': getPath(2) !== "pay",
                                            })}>
                                                <GrCreditCard className={classNames({
                                                    'text-neutral-100 dark:text-neutral-100': getPath(2) === "pay",
                                                    'text-neutral-900 dark:text-neutral-100': getPath(2) !== "pay",
                                                })} />
                                            </div>
                                            <div>Send & Receive</div>
                                        </div>
                                    </Link>


                                    <Link to={`/u/wallet`} className={classNames('bg-[rgb(253,253,253)] dark:bg-[rgb(22,22,22)] px-10 py-3 text-[14px] font-bold opacity-100 w-full block', {
                                        'text-purple-950 dark:text-purple-400 dark:text-opacity-90': getPath(2) === "wallet",
                                        'text-neutral-700 dark:text-neutral-300 text': getPath(2) !== "wallet",
                                    })}>
                                        <div className='flex items-center gap-4'>
                                            <div className={classNames('w-8 h-8 rounded-full text-[18px] flex justify-center items-center shadow-sm', {
                                                'bg-yellow-600 dark:bg-yellow-700': getPath(2) === "wallet",
                                                '': getPath(2) !== "wallet",
                                            })}>
                                                <IoWalletOutline className={classNames({
                                                    'text-neutral-100 dark:text-neutral-100': getPath(2) === "wallet",
                                                    'text-neutral-900 dark:text-neutral-100': getPath(2) !== "wallet",
                                                })} />
                                            </div>
                                            <div>Wallet</div>
                                        </div>
                                    </Link>


                                    <Link to={`/u/transactions`} className={classNames('bg-[rgb(253,253,253)] dark:bg-[rgb(22,22,22)] px-10 py-3 text-[14px] font-bold opacity-100 w-full block', {
                                        'text-purple-950 dark:text-purple-400 dark:text-opacity-90': getPath(2) === "transactions",
                                        'text-neutral-700 dark:text-neutral-300 text': getPath(2) !== "transactions",
                                    })}>
                                        <div className='flex items-center gap-4'>
                                            <div className={classNames('w-8 h-8 rounded-full text-[18px] flex justify-center items-center shadow-sm', {
                                                'bg-yellow-600 dark:bg-yellow-700': getPath(2) === "transactions",
                                                '': getPath(2) !== "transactions",
                                            })}>
                                                <GrTransaction className={classNames({
                                                    'text-neutral-100 dark:text-neutral-100': getPath(2) === "transactions",
                                                    'text-neutral-900 dark:text-neutral-100': getPath(2) !== "transactions",
                                                })} />
                                            </div>
                                            <div>Transactions</div>
                                        </div>
                                    </Link>


                                    <Link to={`/u/beneficiaries`} className={classNames('bg-[rgb(253,253,253)] dark:bg-[rgb(22,22,22)] px-10 py-3 text-[14px] font-bold opacity-100 w-full block', {
                                        'text-purple-950 dark:text-purple-400 dark:text-opacity-90': getPath(2) === "beneficiaries",
                                        'text-neutral-700 dark:text-neutral-300 text': getPath(2) !== "beneficiaries",
                                    })}>
                                        <div className='flex items-center gap-4'>
                                            <div className={classNames('w-8 h-8 rounded-full text-[18px] flex justify-center items-center shadow-sm', {
                                                'bg-yellow-600 dark:bg-yellow-700': getPath(2) === "beneficiaries",
                                                '': getPath(2) !== "beneficiaries",
                                            })}>
                                                <HiUsers className={classNames({
                                                    'text-neutral-100 dark:text-neutral-100': getPath(2) === "beneficiaries",
                                                    'text-neutral-900 dark:text-neutral-100': getPath(2) !== "beneficiaries",
                                                })} />
                                            </div>
                                            <div>Beneficiaries</div>
                                        </div>
                                    </Link>

                                    <Link to={`/u/account`} className={classNames('bg-[rgb(253,253,253)] dark:bg-[rgb(22,22,22)] px-10 py-3 text-[14px] font-bold opacity-100 w-full block', {
                                        'text-purple-950 dark:text-purple-400 dark:text-opacity-90': getPath(2) === "account" && !getPath(3),
                                        'text-neutral-700 dark:text-neutral-300 text': getPath(2) !== "account",
                                    })}>
                                        <div className='flex items-center gap-4'>
                                            <div className={classNames('w-8 h-8 rounded-full text-[18px] flex justify-center items-center shadow-sm', {
                                                'bg-yellow-600 dark:bg-yellow-700': getPath(2) === "account" && !getPath(3),
                                                '': getPath(2) !== "account",
                                            })}>
                                                <FaUserEdit className={classNames({
                                                    'text-neutral-100 dark:text-neutral-100': getPath(2) === "account" && !getPath(3),
                                                    'text-neutral-900 dark:text-neutral-100': getPath(2) !== "account",
                                                })} />
                                            </div>
                                            <div>My Profile</div>
                                        </div>
                                    </Link>

                                    <Link to={`/u/account/kyc`} className={classNames('bg-[rgb(253,253,253)] dark:bg-[rgb(22,22,22)] px-10 py-3 text-[14px] font-bold opacity-100 w-full block', {
                                        'text-purple-950 dark:text-purple-400 dark:text-opacity-90': getPath(2) === "account" && getPath(3) === "kyc",
                                        'text-neutral-700 dark:text-neutral-300 text': getPath(2) !== "account" || getPath(3) !== "kyc",
                                    })}>
                                        <div className='flex items-center gap-4'>
                                            <div className={classNames('w-8 h-8 rounded-full text-[18px] flex justify-center items-center shadow-sm', {
                                                'bg-yellow-600 dark:bg-yellow-700': getPath(2) === "account" && getPath(3) === "kyc",
                                                '': getPath(2) !== "account" || getPath(3) !== "kyc",
                                            })}>
                                                <AiTwotoneProfile className={classNames({
                                                    'text-neutral-100 dark:text-neutral-100': getPath(2) === "account" && getPath(3) === "kyc",
                                                    'text-neutral-900 dark:text-neutral-100': getPath(2) !== "account" || getPath(3) !== "kyc",
                                                })} />
                                            </div>
                                            <div>KYC</div>
                                        </div>
                                    </Link>

                                    <div className='px-10 py-3'>
                                        <hr className='border-purple-300 border-2 border-opacity-30 dark:border-opacity-5' />
                                    </div>

                                    <Link to={`/u/account/security`} className={classNames('bg-[rgb(253,253,253)] dark:bg-[rgb(22,22,22)] px-10 py-3 text-[14px] font-bold opacity-100 w-full block', {
                                        'text-purple-950 dark:text-purple-400 dark:text-opacity-90': getPath(2) === "account" && getPath(3) === "security",
                                        'text-neutral-700 dark:text-neutral-300 text': getPath(2) !== "account" || getPath(3) !== "security",
                                    })}>
                                        <div className='flex items-center gap-4'>
                                            <div className={classNames('w-8 h-8 rounded-full text-[18px] flex justify-center items-center shadow-sm', {
                                                'bg-yellow-600 dark:bg-yellow-700': getPath(2) === "account" && getPath(3) === "security",
                                                '': getPath(2) !== "account" || getPath(3) !== "security",
                                            })}>
                                                <HiShieldCheck className={classNames({
                                                    'text-neutral-100 dark:text-neutral-100': getPath(2) === "account" && getPath(3) === "security",
                                                    'text-neutral-900 dark:text-neutral-100': getPath(2) !== "account" || getPath(3) !== "security",
                                                })} />
                                            </div>
                                            <div>
                                                <div>Security</div>
                                                <div className='font-normal text-xs text-slate-500 text-opacity-80'>Protect your account</div>
                                            </div>
                                        </div>
                                    </Link>

                                    <Link to={`/u/support`} className={classNames('bg-[rgb(253,253,253)] dark:bg-[rgb(22,22,22)] px-10 py-3 text-[14px] font-bold opacity-100 w-full block', {
                                        'text-purple-950 dark:text-purple-400 dark:text-opacity-90': getPath(2) === "support",
                                        'text-neutral-700 dark:text-neutral-300 text': getPath(2) !== "support",
                                    })}>
                                        <div className='flex items-center gap-4'>
                                            <div className={classNames('w-8 h-8 rounded-full text-[18px] flex justify-center items-center shadow-sm', {
                                                'bg-yellow-600 dark:bg-yellow-700': getPath(2) === "support",
                                                '': getPath(2) !== "support",
                                            })}>
                                                <FaQuestionCircle className={classNames({
                                                    'text-neutral-100 dark:text-neutral-100': getPath(2) === "support",
                                                    'text-neutral-900 dark:text-neutral-100': getPath(2) !== "support",
                                                })} />
                                            </div>
                                            <div>
                                                <div>Support</div>
                                                <div className='font-normal text-xs text-slate-500 text-opacity-80'>Chat with support team</div>
                                            </div>
                                        </div>
                                    </Link>

                                    <button onClick={logout} className={classNames('bg-[rgb(253,253,253)] dark:bg-[rgb(22,22,22)] px-10 py-3 text-[14px] font-bold opacity-100 w-full block', {
                                        'text-purple-950 dark:text-purple-400 dark:text-opacity-90': getPath(2) === "logout",
                                        'text-neutral-700 dark:text-neutral-300 text': getPath(2) !== "logout",
                                    })}>
                                        <div className='flex items-center gap-4'>
                                            <div className={classNames('w-8 h-8 rounded-full text-[18px] flex justify-center items-center shadow-sm', {
                                                'bg-yellow-600 dark:bg-yellow-700': getPath(2) === "logout",
                                                '': getPath(2) !== "logout",
                                            })}>
                                                <FaDoorOpen className={classNames({
                                                    'text-neutral-100 dark:text-neutral-100': getPath(2) === "logout",
                                                    'text-neutral-900 dark:text-neutral-100': getPath(2) !== "logout",
                                                })} />
                                            </div>
                                            <div>Logout</div>
                                        </div>
                                    </button>
                                </div>
                            </div>
                            <div className='flex-1 overflow-auto'>
                                {(!user!.kyc.details.find((e) => e.parameter === "bvn") || (user!.kyc.details.find((e) => e.parameter === "bvn") && user!.kyc.details.find((e) => e.parameter === "bvn")!.status === "Pending")) &&
                                    <div className='w-full px-5 py-2 flex justify-between items-center gap-5 bg-orange-900 text-neutral-100'>
                                        <div className='flex items-center gap-2'>
                                            <BsExclamationCircle />
                                            <div className='text-xs'>Complete kyc to unlock wallet and other features</div>
                                        </div>
                                        <Link to={'/onboarding/verify-bvn'} className='text-xs px-2 py-1 rounded-xl bg-orange-950'>Complete</Link>
                                    </div>}
                                <div className='pt-0 md:pt-10 pb-0 md:pb-20'>
                                    <div className='w-full px-0 md:px-0'>
                                        <Outlet />
                                        <div className="py-12 md:py-10"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
                :
                <SplashLoader />
            }
        </>
    )
}

export default UserLayout