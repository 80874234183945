import React from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Hide,
  Show,
  useToast,
} from '@chakra-ui/react'
import {
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from '@chakra-ui/react'
import { walletConfig, walletList } from '@/utils/constants';
import { useNavigate } from 'react-router-dom';
import { useApp } from '@/contexts/appContext';

function AddFundsModal({ children, activeWallet }: { children: React.ReactNode, activeWallet: number }) {

  const navigate = useNavigate();
  const { user, wallets } = useApp();

  const { isOpen: isFundWalletModalOpen, onOpen: onFundWalletModalOpen, onClose: onFundWalletModalClose } = useDisclosure()
  const toast = useToast();

  function handleOnFundWalletModalOpen() {
    const getBvnKycInfo = user!.kyc.details.find((e) => e.parameter === "bvn")!;
    if (getBvnKycInfo.status === "Pending") {
      toast({
        title: 'Error',
        description: 'Complete kyc to continue',
        status: 'warning',
        duration: 5000,
        isClosable: true,
      })
    } else {
      if (!wallets.map(e => e.currency.toLowerCase()).includes(walletList[activeWallet])) {
        toast({
          title: 'Error',
          description: 'Kindly create wallet before funding',
          status: 'warning',
          duration: 5000,
          isClosable: true,
        })
      } else {
        onFundWalletModalOpen();
      }
    }
  }

  function handleOnFundWalletModalClose() {
    onFundWalletModalClose();
  }

  return (
    <>
      <div onClick={handleOnFundWalletModalOpen} className='inline-block cursor-pointer' tabIndex={0}>{children}</div>
      <Hide below='md'>
        <Modal isOpen={isFundWalletModalOpen} onClose={handleOnFundWalletModalClose} closeOnOverlayClick={false}>
          <ModalOverlay />
          <ModalContent>
            {/* <ModalHeader>Title</ModalHeader> */}
            <ModalCloseButton className='text-neutral-800 dark:text-white' />
            <ModalBody className='text-neutral-800 dark:text-white bg-neutral-100 dark:bg-neutral-900'>
              <div className='py-8'>
                <div className='flex mb-2 items-center gap-3'>
                  <div className='font-extrabold text-2xl'>Fund wallet</div>
                  <img src={`/assets/flags/${walletConfig[activeWallet].currency}.svg`} className='w-[25px] h-[25px]' alt="" />
                </div>
                <div className='mb-10'>Select a payment method to fund your {walletConfig[activeWallet].currency.toUpperCase()} ({walletConfig[activeWallet].icon}) wallet</div>

                <button onClick={() => navigate(`/u/pay/fund/bank-transfer?wallet=${walletConfig[activeWallet].currency}`)} className='mb-5 block w-full px-3 py-3 bg-slate-200 dark:bg-neutral-700 bg-opacity-70 dark:bg-opacity-70 rounded-lg'>
                  <div className='flex gap-5 items-center'>
                    <img src="/assets/icons/bank-icon.png" alt="" className='w-[30px]' />
                    <div className='flex justify-between flex-1 items-center'>
                      <div className='font-bold'>Bank Transfer</div>
                    </div>
                  </div>
                </button>

                {/* <button
                  onClick={() => navigate(`/u/pay/fund/card?wallet=${walletConfig[activeWallet].currency}`)} 
                  className='mb-5 block w-full px-3 py-3 bg-slate-200 dark:bg-neutral-700 bg-opacity-70 dark:bg-opacity-70 rounded-lg'>
                  <div className='flex gap-5 items-center'>
                    <img src="/assets/icons/credit-card-icon.png" alt="" className='w-[30px]' />
                    <div className='flex justify-between flex-1 items-center'>
                      <div className='font-bold'>Card</div>
                      <div className='text-[8px] bg-yellow-600 bg-opacity-50 px-2 py-1 rounded-xl text-neutral-100 font-bold'>Coming soon</div>
                    </div>
                  </div>
                </button> */}

                <div className='py-[50px]'></div>
              </div>
            </ModalBody>
          </ModalContent>
        </Modal>
      </Hide>

      <Show below='md'>
        <Drawer placement={"bottom"} isOpen={isFundWalletModalOpen} onClose={handleOnFundWalletModalClose} closeOnOverlayClick={false}>
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton className='text-neutral-800 dark:text-white' />
            <DrawerBody className='text-neutral-800 dark:text-white bg-neutral-100 dark:bg-neutral-900'>
            <div className='py-8'>
                <div className='flex mb-2 items-center gap-3'>
                  <div className='font-extrabold text-2xl'>Fund wallet</div>
                  <img src={`/assets/flags/${walletConfig[activeWallet].currency}.svg`} className='w-[25px] h-[25px]' alt="" />
                </div>
                <div className='mb-10'>Select a payment method to fund your {walletConfig[activeWallet].currency.toUpperCase()} ({walletConfig[activeWallet].icon}) wallet</div>

                <button onClick={() => navigate(`/u/pay/fund/bank-transfer?wallet=${walletConfig[activeWallet].currency}`)} className='mb-5 block w-full px-3 py-3 bg-slate-200 dark:bg-neutral-700 bg-opacity-70 dark:bg-opacity-70 rounded-lg'>
                  <div className='flex gap-5 items-center'>
                    <img src="/assets/icons/bank-icon.png" alt="" className='w-[30px]' />
                    <div className='flex justify-between flex-1 items-center'>
                      <div className='font-bold'>Bank Transfer</div>
                    </div>
                  </div>
                </button>

                {/* <button
                  onClick={() => navigate(`/u/pay/fund/card?wallet=${walletConfig[activeWallet].currency}`)} 
                  className='mb-5 block w-full px-3 py-3 bg-slate-200 dark:bg-neutral-700 bg-opacity-70 dark:bg-opacity-70 rounded-lg'>
                  <div className='flex gap-5 items-center'>
                    <img src="/assets/icons/credit-card-icon.png" alt="" className='w-[30px]' />
                    <div className='flex justify-between flex-1 items-center'>
                      <div className='font-bold'>Card</div>
                      <div className='text-[8px] bg-yellow-600 bg-opacity-50 px-2 py-1 rounded-xl text-neutral-100 font-bold'>Coming soon</div>
                    </div>
                  </div>
                </button> */}

                <div className='py-[50px]'></div>
              </div>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </Show>
    </>
  )
}

export default AddFundsModal