import React from 'react'

function TestPage() {
  return (
    <>
      <div className='pt-8 md:pt-7'>
        <div className='mb-10 px-6'>
          <div className='font-extrabold text-xl'>Test page</div>
        </div>

        <div className='px-6'>
          Test
        </div>
      </div>
    </>
  )
}

export default TestPage